<template>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">
        
            <div class="nano">
                <div class="nano-content">
                    <SideBar/>
                </div>
        
            </div>
        
        </aside>
        <!-- end: sidebar -->

        <section role="main" class="content-body">
            <header class="page-header">
                <h2>Dashboard</h2>
            
                <div class="right-wrapper pull-right">
                    <ol class="breadcrumbs">
                        <li>
                            <a href="/dashboard">
                                <i class="fa fa-home"></i>
                            </a>
                        </li>
                    </ol>
                </div>
            </header>
            
            <div class="panel" >
                <div class="panel-body text-center viral-g-create" >
                    <img src="/images/start-social.png"  style="margin:15px;">
                    <h4 class="text-bold" style="margin:10px;"> Create your first 
                        <span class="word-rotate highlight" style="height:23px !important;">
                            <span class="word-rotate-items text-uppercase">
                                <span>Facebook</span>
                                <span>Twitter</span>
                                <span>Instagram</span>
                                <span>Youtube</span>
                                <span>TikTok</span>
                                <span>Threads</span>
                            </span>
                        </span>
                        Advert
                    </h4>
                    <router-link to="new-advert">
                        <button class="mb-xl mt-xl mr-xl ml-xl btn btn-lg btn-primary" >
                            Get Started
                        </button>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class=" col-sm-12 col-md-6 ">
                    <div class="panel">
                        <h4 class="text-bold viral-advert-intro-h" >Create something new</h4>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row" style="padding:5px 15px;" >
                                    <router-link to="new-advert#facebook-advert-pane">
                                        <div role="button" class="col-xs-2 col-sm-2 col-md-2 viral-col viral-advert-icon-btn">
                                            <i class="fa fa-facebook "></i>
                                        </div>
                                    </router-link>
                                    
                                    <div class=" col-xs-10 col-sm-10 col-md-10 viral-advert-cont">
                                        <router-link to="new-advert#facebook-advert-pane">
                                            Create Facebook Advert Task
                                        </router-link>
                                        <p>Get Likes, Followers and Views</p>
                                    </div>
                                </div>
                                <div class="row " style=" padding:5px 15px;">
                                    <router-link to="new-advert#twitter-advert-pane">
                                        <div role="button" class="col-xs-2 col-sm-2 col-md-2 viral-col viral-advert-icon-btn">
                                            <i class="fa fa-twitter"></i>
                                        </div>
                                    </router-link>
                                    <div class="col-xs-10 col-sm-10 col-md-10 viral-advert-cont">
                                        <router-link to="new-advert#twitter-advert-pane">
                                            Create Twitter Advert Task
                                        </router-link>
                                        <p>Get Retweets, Comments, Followers and Views</p>
                                    </div>
                                </div>
                                <div class="row" style="padding:5px 15px;" >
                                    <router-link to="new-advert#threads-advert-pane">
                                        <div role="button" class="col-xs-2 col-sm-2 col-md-2 viral-col viral-advert-icon-btn">
                                            <img src="/img/threads.png" style="height: 40px; max-width: 100%; object-fit: contain;" />
                                        </div>
                                    </router-link>
                                    <div class="col-xs-10 col-sm-10 col-md-10 viral-advert-cont">
                                        <router-link to="new-advert#threads-advert-pane">
                                            Create Threads Advert Task
                                        </router-link>
                                        <p>Get Likes, Followers, Comments, Shares and Views</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-md-6">
                                <div class="row" style="padding:5px 15px;">
                                    <router-link to="new-advert#instagram-advert-pane">
                                        <div role="button" class="col-xs-2 col-sm-2 col-md-2 viral-col viral-advert-icon-btn">
                                            <i class="fa fa-instagram"></i>
                                        </div>
                                    </router-link>
                                    <div class="col-xs-10 col-sm-10 col-md-10 viral-advert-cont">
                                        <router-link to="new-advert#instagram-advert-pane">
                                            Create Instagram Advert Task
                                        </router-link>
                                        <p>Get Likes, Followers and Views</p>
                                    </div>
                                </div>
                                <div class="row" style="padding:5px 15px;" >
                                    <router-link to="new-advert#youtube-advert-pane">
                                        <div role="button" class="col-xs-2 col-sm-2 col-md-2 viral-col viral-advert-icon-btn">
                                            <i class="fa fa-youtube"></i>
                                        </div>
                                    </router-link>
                                    <div class="col-xs-10 col-sm-10 col-md-10 viral-advert-cont">
                                        <router-link to="new-advert#youtube-advert-pane">
                                            Create Youtube Advert Task
                                        </router-link>
                                        <p>Get Likes, Comments, Subscribers and Views</p>
                                    </div>
                                </div>
                                <div class="row" style="padding:5px 15px;" >
                                    <router-link to="new-advert#tiktok-advert-pane">
                                        <div role="button" class="col-xs-2 col-sm-2 col-md-2 viral-col viral-advert-icon-btn">
                                            <img src="/img/tiktok.svg" style="height: 40px; max-width: 100%; object-fit: contain;" />
                                        </div>
                                    </router-link>
                                    <div class="col-xs-10 col-sm-10 col-md-10 viral-advert-cont">
                                        <router-link to="new-advert#tiktok-advert-pane">
                                            Create TikTok Advert Task
                                        </router-link>
                                        <p>Get Likes, Followers, Comments, Saves and Views</p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="panel">
                        <h4 class="text-bold viral-advert-intro-h" >Latest created social adverts</h4>
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="table table-striped mb-none">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Date</th>
                                            <th class="text-center">Advert</th>
                                            <th class="text-center">Type</th>
                                            <th class="text-center">Target</th>
                                            <th class="text-center">Progress</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="advert in latestAdverts.slice(0,4)">
                                            <td class="text-center">{{ advert['created_at'] }}</td>
                                            <td class="text-center">{{ advert['platform'] }}</td>
                                            <td class="text-center">{{ advert['type'] }}</td>
                                            <td class="text-center">{{ advert['users_needed'] }}</td>
                                            <td class="text-center">
                                                <span class="label label-success" v-if="advert['status'] == 'complete'">{{ advert['status'] }}</span>
                                                <span class="label label-warning" v-else-if="advert['status'] == 'active'">{{ advert['status'] }}</span>
                                                <span class="label label-default" v-else-if="advert['status'] == 'pending'">{{ advert['status'] }}</span>
                                                <span class="label label-danger" v-else>{{ advert['status'] }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                                      
                    </div>
                </div>

            </div>

            <!-- end: page -->
        </section>
    </div>
</template>
<script>
import SideBar from "./../includes/SideBar";
export default {
    components: {
        SideBar,
    },
    data: () => ({
        baseUrl:"",
        accessToken:"",
        latestAdverts:[],
    }),
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.getAdverts()
    },
    methods: {
        getAdverts(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/tasks',
                method: 'POST',
                data: {'access_token':vueThis.accessToken,'type':'all'},
                success: function(response){
                    console.log(response)
                    vueThis.latestAdverts = response.data
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
    }
};
</script>
<style type="text/css">
</style>
