require('babel-polyfill')

const auth = {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem('access_token'),
    materialTypes: [],
    // currentUser: JSON.parse(localStorage.getItem('user'))
  },
  getters: {
    token: state => state.accessToken,
    isAuthenticated: state => state.authenticated,
    user: state => (state.currentUser === null) ? {} : state.currentUser,
    name: state => (state.currentUser === null) ? '' : state.currentUser.name
  },
  mutations: {
    storeType (state, payload) {
      state.materialTypes = payload
    }
  },
  actions: {
    async setMaterialTypes ({ commit }, materialTypes) {
      commit('storeType', materialTypes)
    }
  }
}

export default auth
