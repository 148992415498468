<template>
  	<!-- start: header -->
	<header class="header">
		<div class="logo-container">
			<a href="" class="logo">
				<img src="/images/logo.png" height="45" alt="ViralTrend" />
			</a>
			<div id="mobileMenu" class="visible-xs toggle-sidebar-left" data-toggle-class="sidebar-left-opened" data-target="html" data-fire-event="sidebar-left-opened">
				<i class="fa fa-bars " aria-label="Toggle sidebar" style="font-size: 16px;"></i>
			</div>
		</div>
	
		<!-- start: search & user box -->
		<div class="header-right">
			<div class="userbox wallet-balance">
				<div>
					<span>
						Account :&nbsp;{{ currencyCode }}
						<span>
							{{ walletBalance }}
						</span>
					</span>
				</div>
			</div>
			<span class="separator"></span>
	
			<div id="userbox" class="userbox">
				<a href="#" data-toggle="dropdown">
					<div class="profile-info" data-lock-name=" " data-lock-email="">
						<span class="name text-capitalize">{{ fullName }}</span>
						<span class="role text-capitalize">advertiser</span>
					</div>
	
					<i class="fa custom-caret"></i>
				</a>
	
				<div class="dropdown-menu">
					<ul class="list-unstyled">
						<li class="divider"></li>
						<li>
							<router-link role="menuitem" tabindex="-1" to="profile">
								<i class="fa fa-user"></i> My Profile
							</router-link>
						</li>
						<li>
							<a role="menuitem" tabindex="-1" v-on:click="logout">
								<i class="fa fa-power-off"></i> Logout
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- end: search & user box -->
		<div class="notificationMessage" v-if="notificationMessage" v-on:click="clearNoty">
			<p>
				{{ notificationMessage }}
			</p>
		</div>
	</header>
	<!-- end: header -->
</template>
<script>
export default {
    data: () => ({
    	userName:'',
    	fullName:'',
    	email:'',
    	walletBalance:'0',
    	currencyCode:'',
    	notificationMessage:'',
    }),
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
    	this.getUser()
    	this.checkNoty()
    	this.getWallet()
    },
    methods: {
    	getUser(){
            this.user = localStorage.getItem('user')
            if(this.user){
                let u = JSON.parse(this.user)
                this.userName = u['username']
                this.fullName = u['name']
            }else{
                this.$router.push({ path: '/login' })
            }
            console.log(this.user,'this user')
    	},
        getWallet(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/wallet',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
					console.log(response,"wallet data")
					vueThis.$parent.checkToken(response)
                    vueThis.walletBalance = parseFloat(response.data['amount']).toFixed(2)
                    vueThis.currencyCode = response.rave['currencyCode']
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        checkNoty(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/get_noty',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
                    console.log(response,"noty data")
					vueThis.$parent.checkToken(response)
                    let notyKey = response.data['id']
                    let notyMessage = response.data['message']
                    if(notyMessage){
	                    vueThis.updateNoty(notyKey)
	                    vueThis.notificationMessage = notyMessage
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        clearNoty(){
            this.notificationMessage = '';
        },
        updateNoty(notyKey){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/update_noty',
                method: 'POST',
                data: {'access_token':vueThis.accessToken,'key':notyKey},
                success: function(response){
                    console.log(response,"noty data")
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
    	logout(){
      		this.$store.dispatch('auth/logout').then(() => {
        		this.$router.push({ path: '/login' })
      		})
    	}
    }
};
</script>
<style type="text/css">
	.notificationMessage {
		background-color: #263;
		color: #fff;
		z-index: 99999;
		text-align: center;
		padding: 5px;
		position: fixed;
		width: 100%;
	}
</style>
