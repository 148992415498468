import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'
import router from './router'
import store from './store/index'

import Default from './components/layouts/Default.vue'
import Welcome from './components/layouts/Welcome.vue'

import Axios from 'axios'
import VueToastr from 'vue-toastr'
import VueAjaxUploader from '@bkstar18/vue-ajax-uploader';
import CKEditor from '@ckeditor/ckeditor5-vue';

Vue.use(CKEditor);

import 'jodit/build/jodit.min.css'
import JoditVue from 'jodit-vue'

Vue.use(JoditVue)

window.$ = window.jQuery = require('jquery');

Vue.use(VueRouter)
Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultPosition: 'toast-top-right',
    defaultCloseOnHover: false
})

Vue.component('default-layout', Default)
Vue.component('welcome-layout', Welcome)

Vue.config.productionTip = false
Vue.config.productionSourceMap = true
    // change API Host based on platform
let host = window.location.hostname;
Vue.prototype.$HOST_URL = 'https://api.vitisocial.com/'
Vue.prototype.$BASE_URL = 'https://vitisocial.com/'
Vue.prototype.$AdvertiserHost = 'https://advertisers.vitisocial.com/'
Vue.prototype.$PromoterHost = 'https://promoters.vitisocial.com/'

let checkLocal = host.search("localhost");
let checkLocalIP = host.search("127.0.0.1");
if (checkLocal > -1 || checkLocalIP > -1) {
    Vue.config.productionTip = false
    Vue.config.productionSourceMap = true
    Vue.prototype.$HOST_URL = 'http://localhost:6020/'
    Vue.prototype.$BASE_URL = 'http://127.0.0.1:8080/'
    Vue.prototype.$AdvertiserHost = 'http://127.0.0.1:8081/'
    Vue.prototype.$PromoterHost = 'http://127.0.0.1:8081/'
}
checkLocal = host.search("advertisersdev.vitisocial.com");
console.log(checkLocal, host)
if (checkLocal > -1) {
    Vue.config.productionTip = false
    Vue.prototype.$HOST_URL = 'https://apidev.vitisocial.com/'
    Vue.prototype.$BASE_URL = 'https://advertisersdev.vitisocial.com/'
    Vue.prototype.$AdvertiserHost = 'https://advertisersdev.vitisocial.com/'
    Vue.prototype.$PromoterHost = 'https://promotersdev.vitisocial.com/'
}
let checkLive = host.search("advertisers.vitisocial.com");
let checkLive1 = host.search("advertiser.vitisocial.com");
console.log(checkLive, checkLive1)
if (checkLive > -1 || checkLive1 > -1) {
    Vue.config.productionTip = false
    Vue.prototype.$HOST_URL = 'https://api-two.vitisocial.com/'
    Vue.prototype.$BASE_URL = 'https://advertisers.vitisocial.com/'
    Vue.prototype.$AdvertiserHost = 'https://advertisers.vitisocial.com/'
    Vue.prototype.$PromoterHost = 'https://promoters.vitisocial.com/'
}

// Upload component  
Vue.use(VueAjaxUploader);

Vue.prototype.$http = Axios

Vue.prototype.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const accessToken = localStorage.getItem('access_token')
if (accessToken) {
    Vue.prototype.$http.defaults.headers.common['x-access-token'] = accessToken
    Vue.prototype.$http.defaults.headers.common['Authorization'] = accessToken
}

Vue.filter('capitalize', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('truncate', function(value, length) {
    if (!value) return ''
    value = value.toString()
    return (value.length >= length) ? value.substring(0, length) + '...' : value
})

Vue.filter('capitalizeAll', function(value) {
    if (!value) return ''
    value = value.toString()
    let text = value.split(' ')
    let newText = ''
    for (let i = 0; i < text.length; i++) {
        newText += text[i].charAt(0).toUpperCase() + text[i].slice(1).toLowerCase() + ' '
    }
    return newText.trim()
})

Vue.filter('removeHash', function(value) {
    if (!value) return ''
    value = value.toString()
    let text = value.split('-')
    let newText = ''
    for (let i = 0; i < text.length; i++) {
        newText += text[i].charAt(0).toUpperCase() + text[i].slice(1).toLowerCase() + ' '
    }
    return newText.trim()
})

/* eslint-disable-next-line no-new */
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')