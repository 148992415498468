<template>
    <section class="body-sign">
        <div class="center-sign">
            <a href="/" class="logo pull-left">
                <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
            </a>

            <div class="panel panel-sign">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Fast Log In</h2>
                </div>
            </div>

            <p class="text-center text-muted mt-md mb-md" >
                &copy; Copyright 2023, 
                <span>
                    <a href="https://viraltrend.org">ViralTrend</a>
                </span>
            </p>
        </div>
    </section>
</template>
<script>
export default {
    data: () => ({
        baseUrl: "",
        key: "",
    }),
    mounted () {
        this.baseUrl = this.$BASE_URL
        let r = this.$route
        this.key = r.params.key
        console.log(r,this.key)
        this.autoLoginAPI()
    },
    methods: {
        autoLoginAPI(){
            this.processing = true

            if (this.key === '') {
                this.processing = false
                this.$toastr.w('Warning', 'Please fill in complete login details')
                return false
            }
            let user = {
                'access_token':this.key
            }
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/autologin',
                method: 'POST',
                data: user,
                success: function(response){
                    console.log(response,"Auto login response")
                    if(response.status == 'success'){
                        vueThis.$toastr.s('Success', 'Login successful, loading dashboard')
                        // if(response.user['type'] == 'advertiser'){
                            vueThis.$store.dispatch('auth/login', response.user).then(() => {
                                // if(response.user['type'] == 'advertiser'){
                                    window.location.href = '/dashboard'
                                    // vueThis.$router.push({ path: '/dashboard' })
                                // }else if(response.user['type'] == 'promoter'){
                                //     // window.location.href = vueThis.$PromoterHost+'dashboard'
                                //     // vueThis.$router.push({ path: '/dashboard' })
                                // }else{
                                //     vueThis.$toastr.e('Error', 'User type error')
                                // }
                                // window.location.href = "/#/dashboard"
                            })
                        // }else{
                        //     vueThis.$toastr.e('Error', 'This section is for advertisers only')
                        // }
                    }else{
                        vueThis.$toastr.e('Error', response.message)
                    }
                },
                error: function(response){
                    console.log(response)
                }
            })
        }
    }
};
</script>
<style type="text/css">
    #noEmail, #noVerification {
        display: none;
    }
</style>
