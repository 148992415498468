import Vue from 'vue'
import Vuex from 'vuex'
import AuthStore from './modules/authStore'
import UserStore from './modules/userStore'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth: AuthStore,
    user: UserStore
  }
})

export default store