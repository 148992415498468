<template>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">
        
            <div class="nano">
                <div class="nano-content">
                    <SideBar/>  
                </div>
        
            </div>
        
        </aside>
        <!-- end: sidebar -->

        <section role="main" class="content-body">
            <div class="row">
                <div class="col-sm-4">
                    <section class="panel" v-if="advert">
                        <div class="panel-body">
                            <header class="panel-heading bg-primary  mb-md">

                                <div class="widget-profile-info">
                                    <div class="profile-info">
                                        <h4 class="name text-semibold text-capitalize">{{ advert['platform'] }}</h4>
                                        <h5 class="role text-capitalize">{{ advert['type'] }}</h5>
                                        <div class="profile-footer" style="color:#dd8300;">
                                            <span id="user-state">{{ advert['countries'] }}</span>&nbsp;-&nbsp;
                                            <span id="user-country">{{ advert['created_at'] }}</span>
                                        </div>
                                    </div>
                                </div>

                            </header>

                            <div class="widget-toggle-expand mb-md">
                                <div class="widget-header">
                                    <h6>Advert details</h6>
                                </div>
                                <div class="widget-content-collapsed">
                                </div>
                                <div class="widget-content-expanded">
                                    <ul class="simple-todo-list">
                                        <li class="hidden">Amount: {{ currencyCode }} {{ advert['amount'] }}</li>
                                        <li v-if="advert['comment']">Comment: {{ advert['comment'] }}</li>
                                        <li>Users Needed: {{ advert['users_needed'] }}</li>
                                        <li style="word-break: break-all;">URL: {{ advert['url'] }}</li>
                                        <li>Instructions:</li>
                                    </ul>
                                    <div v-html="advert['instruction']"></div>
                                </div>
                            </div>
                            <hr class="dotted short">
                            <div class="social-icons-list">
                                <span class="label label-success" v-if="advert['status'] == 'complete'">
                                    {{ advert['status'] }}
                                </span>
                                <span class="label label-waring" v-else-if="advert['status'] == 'active'">
                                    {{ advert['status'] }}
                                </span>
                                <span class="label label-danger" v-else>
                                    {{ advert['status'] }}
                                </span>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="col-sm-8">
                    <section class="panel" v-if="advert.status != 'pending'">
                        <div class="panel-body">
                            <header class="panel-heading bg-primary  mb-md">

                                <div class="widget-profile-info">
                                    <div class="profile-info">
                                        <h4 class="name text-semibold">Performed by</h4>
                                        <h5 class="role">{{ performers.length }}</h5>
                                        <div class="profile-footer" style="color:#dd8300;">
                                        </div>
                                    </div>
                                </div>

                            </header>

                            <div class="widget-toggle-expand mb-md">
                                <div class="widget-header">
                                </div>
                                <div class="widget-content-collapsed">
                                </div>
                                <div class="widget-content-expanded table-responsive">
                                    <table class="table table-striped mb-none">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Name
                                                </th>
                                                <th>
                                                    Email
                                                </th>
                                                <th>
                                                    Time
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="p in performers">
                                                <td>
                                                    <template v-if="p['user']">
                                                        {{ p['user']['name'] }}
                                                    </template>
                                                </td>
                                                <td>
                                                    <template v-if="p['user']">
                                                    </template>
                                                </td>
                                                <td>
                                                    {{ p['created_at'] }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <hr class="dotted short">
                            <div class="social-icons-list">
                                <span class="label label-warning">More</span>
                            </div>
                        </div>
                    </section>

                    <section class="panel" v-if="advert.status == 'pending'">
                        <div class="panel-body">
                            <header class="panel-heading bg-primary  mb-md">

                                <div class="widget-profile-info">
                                    <div class="profile-info">
                                        <h4 class="name text-semibold">Choose Payment Method - {{ currencyCode }} {{ amount }}</h4>
                                    </div>
                                </div>

                            </header>

                            <div class="widget-toggle-expand mb-md">
                                <div class="widget-header">
                                </div>
                                <div class="widget-content-collapsed">
                                </div>
                                <div class="widget-content-expanded">
                                    <div class="row text-center">
                                        <div class="col-sm-6">
                                            <div v-if="loadRave == 'true'">
                                                <Rave
                                                   style-class="btn btn-success"
                                                   is-production="false"
                                                   :email="email"
                                                   :amount="amount"
                                                   :reference="reference"
                                                   :rave-key="raveKey"
                                                   :callback="callback"
                                                   :close="close"
                                                   :metadata="meta"
                                                   :customerFirstname="fname"
                                                   paymentOptions="card,barter,account,ussd"
                                                   hostedPayemt="1"
                                                   customTitle="ViralTrend"
                                                   :currency="currencyCode"
                                                   :country="countryCode"
                                                ><i>Pay with Rave</i></Rave>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <button v-if="walletBalance >= amount" class="btn btn-primary" v-on:click="payWithWallet">
                                                Pay with Wallet - {{ currencyCode }} {{ walletBalance }}
                                            </button>
                                            <button v-else class="btn btn-primary" disabled>
                                                Pay with Wallet - {{ currencyCode }} {{ walletBalance }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="dotted short">
                            <div class="social-icons-list">
                                <span class="label label-warning">Note: Advert will only be sent to promoters after payment</span>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
            
    </div>
</template>
<script>
import Rave from 'vue-ravepayment';
import SideBar from "./../includes/SideBar";
export default {
    components: {
        SideBar,
        Rave,
    },
    data: () => ({
        baseUrl: "",
        advertKey: "",
        advert:[],
        performers:[],
        user:[],
        raveProdEnv: "false",
        loadRave: "",
        raveKey: "",
        currencyCode: "",
        countryCode: "",
        email: "",
        amount: 0,
        walletBalance: 0,
        fname: "",
        lname: "",
        redirect: "",
        meta: [{
            metaname: 'Viral Trend',
            metavalue: 'Advert Payment'
        }],
        sub: [
            {
                id: "1324"
            },
            {
                id: "1221"
            }
        ]
    }),
    computed: {
        reference(){
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
     
            for( let i=0; i < 10; i++ )
              text += possible.charAt(Math.floor(Math.random() * possible.length));
     
            return 'VT-AD-'+this.advertKey+'-'+text;
        }
    },
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.init()
        this.getWallet()
        this.getAdvert()
    },
    methods: {
        init(){
            // load user data
            this.user = localStorage.getItem('user')
            if(this.user){
                this.user = JSON.parse(this.user)
            }
            this.fname = this.user['name']
            this.email = this.user['email']
            console.log(this.email,this.fname)
            // get the advert key
            let advertKey = this.$route.path
            let splitKey = advertKey.split("/")
            this.advertKey = splitKey[splitKey.length - 1]
        },
        getAdvert(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/task',
                method: 'POST',
                data: {'access_token':vueThis.accessToken,'key':vueThis.advertKey},
                success: function(response){
                    console.log(response)
                    vueThis.advert = response.data
                    if(response.performers){
                        vueThis.performers = response.performers
                    }
                    if(vueThis.advert['status'] == 'pending'){
                        vueThis.amount = response.advertAmount
                        vueThis.raveKey = response.rave['publicKey']
                        vueThis.currencyCode = response.rave['currencyCode']
                        vueThis.countryCode = response.rave['countryCode']
                        if(response.rave['account'] == 'live'){
                            console.log("Rave live")
                            vueThis.raveProdEnv = "true"
                        }else{
                            vueThis.raveProdEnv = "false"
                            console.log("Rave test")
                        }
                        if(vueThis.raveKey){
                            vueThis.initializeRave();
                        }else{
                            console.log("missing Rave key, unable to initialize")
                        }
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        getWallet(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/wallet',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
                    vueThis.walletBalance = response.data['amount']
                    vueThis.raveKey = response.rave['publicKey']
                    vueThis.currencyCode = response.rave['currencyCode']
                    vueThis.countryCode = response.rave['countryCode']
                    if(vueThis.raveKey){

                    }else{
                        this.$toastr.w('Warning', 'Kindly reload this page')
                    }
                    if(response.rave['account'] == 'live'){
                        console.log("Rave live")
                        vueThis.raveProdEnv = "true"
                    }else{
                        vueThis.raveProdEnv = "false"
                        console.log("Rave test")
                    }
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        initializeRave(){
            console.log(this.raveKey,this.amount,this.raveProdEnv)
            this.loadRave = "true"
        },
        callback: function(response){
            console.log(response,"final response")
            let txRef = response['tx']['txRef'];
            if(txRef){
                this.$parent.preloader('show');
                let vueThis = this;
                let vueWin = window;
                $.ajax({
                    url: vueThis.$HOST_URL + 'api/pay_with_rave',
                    method: 'POST',
                    data: {'access_token':vueThis.accessToken,'transaction_id':txRef,'key':vueThis.advertKey},
                    success: function(response){
                        console.log(response,"fund response")
                        vueThis.$parent.noty(response.status,response.message)
                        if(response.status == "success"){
                            vueWin.location.href = vueWin.location.href
                        }
                        vueThis.$parent.preloader('hide')
                    },
                    error: function(response){
                        console.log(response)
                        vueThis.$parent.preloader('hide')
                    }
                })
            }else{
                this.$toastr.w('Warning', 'Transaction reference could not be found')
            }
        },
        close: function(){
            console.log("Payment closed")
        },
        payWithWallet(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/pay_with_wallet',
                method: 'POST',
                data: {'access_token':vueThis.accessToken,'key':vueThis.advertKey},
                success: function(response){
                    console.log(response,"pay with wallet response")
                    vueThis.$parent.noty(response.status,response.message)
                    if(response.status == "success"){
                        vueWin.location.href = vueWin.location.href
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        }
    }
};
</script>
<style type="text/css">
</style>
