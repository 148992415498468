<template>
    <div class="inner-wrapper new-advert-type">
        <!-- start: sidebar -->
        <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">
        
            <div class="nano">
                <div class="nano-content">
                    <SideBar/>  
                </div>
        
            </div>
        
        </aside>
        <!-- end: sidebar -->

        <section role="main" class="content-body">
            <header class="page-header">
                <h2>Create Adverts</h2>
            
                <div class="right-wrapper pull-right">
                    <ol class="breadcrumbs">
                        <li>
                            <a href="create-advert">
                                <i class="fa fa-share-alt-square"></i> {{taskPrices.length}}
                            </a>
                        </li>
                    </ol>
                </div>
            </header>
            <div class="panel" style="margin-bottom:unset" v-if="taskPrices.length != []">
                <template v-if="infoSection == 'false'">
                    <h2 class="create-advert-intro">Create Adverts</h2>
                    <h4 class="text-bold">Choose an Advert</h4>
                    <div>
                        <b>Task</b>: Open Instagram App and search for @{{ handle }} and {{ instructions }}
                    </div>
                    <div class="col-sm-12 viral-col">
                        <div class="tabs viral-advert-tab-hostOLD">
                            <ul class="nav nav-tabs nav-justified  viral-adverts-select-tabs">
                                <li class="active" data-toggle="tab" data-target="#instagram-advert-pane" v-on:click="changePlatform('instagram')" id="instagramTabButton">
                                    <a data-toggle="tooltip" data-placement="top" title="Create Instagram Adverts" v-on:click="changeType('follow',taskPrices['instagram']['follow'])">
                                        <div class="text-center select-advert-box">
                                            <i class="fa fa-instagram fa-3x"></i>
                                        </div>
                                        Instagram Advert
                                    </a>
                                </li>
                            </ul>
                            <br/>
                            <div class="">
                                <label>
                                    Advert type:
                                </label>
                                <select class="form-control" v-model="type">
                                    <option value="" >
                                        Please select the Ad Type
                                    </option>
                                    <option value="follow">Follow - {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['follow'] }} / per follower</option>
                                    <option value="view">View - {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['view'] }} / per view</option>
                                    <option value="like">Like - {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['like'] }} / per like</option>
                                    <option value="comment">Comment - {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['comment'] }} / per comment</option>
                                </select>
                            </div>
                            <div>
                                <template v-if="type == 'follow'">
                                    <label>Handle to {{ type }}</label>
                                    <input v-model="handle" class="form-control" type="text" placeholder="The Instagram handle or username you want promoters to follow eg @viraltrend" />
                                </template>
                                <template v-else>
                                    <label>Post to {{ type }}</label>
                                    <input v-model="handle" class="form-control" placeholder="Eg First post" type="text" />
                                </template>
                            </div>
                            <div>
                                <label>Instructions</label>
                                <textarea v-model="instructions" placeholder="Eg Like the first post" class="form-control"></textarea>
                            </div>
                            <div>
                                <label>Screenshot of handle or post</label>
                                <input v-model="screenshot" class="form-control" type="text" />
                                <DykeUploads :upload-url="'api/upload_file'"/>
                            </div>
                            <div>
                                <label v-if="type == 'follow'">URL of Handle to {{ type }}</label>
                                <label v-else>URL of Post to {{ type }}</label>
                                <input v-model="socialLink" class="form-control" placeholder="Instagram full link so we dont show ads to promoters twice" type="text" />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div v-html="advertiser_warning">
                    </div>
                    <div class="text-center">
                        <button class="btn btn-success" v-on:click="ContinueToAd">Continue</button>
                    </div>
                </template>
            </div>
            <div v-else class="text-center">
                <h3>
                    Loading...
                </h3>
            </div>

            <div class="">
                <!-- Modal Animation -->
                <div id="facebook-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Facebook Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-facebook-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a facebook advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;
                                        <span class="advert-task-value">
                                            {{ localizationData['currency'] }}
                                            <span id="advert-task-cost">{{ total }}</span>
                                    </span>
                                    </p>

                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p>
                                    <span><i class="fa fa-warning"></i> &nbsp;</span>
                                    You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.
                                </p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="twitter-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Twitter Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-twitter-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a twitter advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="instagram-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Instagram Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-instagram"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a instagram advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="youtube-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Youtube Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-youtube-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a youtube advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="website-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Website Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-youtube-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a youtube advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
            </div>
            <!-- end: page -->
        </section>
    </div>
</template>
<script>
import SideBar from "./../includes/SideBar";
import advertCountries from "./../includes/advertCountries";
import DykeUploads from "./../includes/DykeUploads";

export default {
    components: {
        SideBar,
        advertCountries,
        DykeUploads,
    },
    data: () => ({
        baseUrl: "",
        hostUrl: "",
        taskPrices:[],
        localizationData:[],
        type:'',
        socialLink:'',
        usersNo:250,
        total:'',
        socialComment:'',
        accessToken:'',
        handle:'',
        instructions:'',
        screenshot:'',
        content:'',
        countries:[],
        infoSection:'true',
        advertiser_warning:'',
    }),
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.hostUrl = this.$HOST_URL
        this.getAdRates()
        this.getLocalization()
        this.changeDescription()
        this.loadSummernote()
    },watch: {
        editorData: function() {
            console.log(this.editorData,'>>>> ');
            if(this.editorData){
                $(".viral-create-specific").show('slow')
            }else{
                $(".viral-create-specific").hide('slow')
            }
        }
    },
    methods: {
        init(){
            this.changePlatform("instagram")
            this.switchTab()
        },
        switchTab(){
            let q = window.location
            console.log(q,"//")
            let h = q.hash
            let vueThis = this
            if(h){
                $(document).ready(function(){
                    let fb = h.search('facebook')
                    let tw = h.search('twitter')
                    let ig = h.search('instagram')
                    let yt = h.search('youtube')
                    console.log(h,fb,tw,ig,yt)
                    $("#facebookTabButton").removeClass("active")
                    $("#twitterTabButton").removeClass("active")
                    $("#instagramTabButton").removeClass("active")
                    $("#youtubeTabButton").removeClass("active")
                    if(fb > -1){
                        $("#facebookTabButton").addClass("active")
                        vueThis.changePlatform("facebook")
                    }
                    if(tw > -1){
                        $("#twitterTabButton").addClass("active")
                        vueThis.changePlatform("twitter")
                    }
                    if(ig > -1){
                        $("#instagramTabButton").addClass("active")
                        vueThis.changePlatform("instagram")
                    }
                    if(yt > -1){
                        $("#youtubeTabButton").addClass("active")
                        vueThis.changePlatform("youtube")
                    }
                })
            }
        },
        updateCountry(country){
            console.log(country,">> PARENT")
            this.countries = country
        },
        getAdRates(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/ad_rates',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
                    console.log(response)
                    vueThis.taskPrices = response.data || []
                    vueThis.advertiser_warning = response.data['advertisers_warning']
                    vueThis.init()
                    if(vueThis.taskPrices.length != []){
                        vueThis.changeType(vueThis.platform,vueThis.taskPrices['facebook']['like'])
                    }
                    console.log(vueThis.taskPrices,vueThis.taskPrices.length)
                    if(response.status == 'success'){
                    }else{
                        vueThis.$parent.noty(response.status,response.message);
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        getLocalization(){
            this.$parent.preloader('show');
            this.processing = true
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/localization',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
                    console.log(response)
                    if(response.status == 'success'){
                        vueThis.localizationData = response.data
                    }else{
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        changePlatform: function(platform){
            this.usersNo = 250;
            this.platform = platform;
            if (platform == 'facebook') {
                this.type = 'like';
            }
            if (platform == 'twitter') {
                this.type = 'follow';
            }
            if (platform == 'instagram') {
                this.type = 'follow';
            }
            if (platform == 'youtube') {
                this.type = 'subscribe';
            }
            if (platform == 'website') {
                this.type = 'vote';
            }
            if (this.type == 'like') {
                this.usersNo = 250;
            }else{
                this.usersNo = 250;
            }
            console.log(this.platform,this.type,"platform and type")
        },
        changeType: function(type,price){
            console.log(type);
            console.log(price);
            if (this.type == 'like') {
                this.usersNo = 250;
            }else{
                this.usersNo = 250;
            }
            this.type = type;
            this.price = price;
        },
        changePrice: function(price){
            this.price = price;
            console.log(price);
        },
        priceDefault: function(){
            this.price = $("#fbLoad").val();
            console.log(this.price);
        },
        getTotal: function(event){
            var elem = event.target;
            this.usersNo = $(elem.parentElement.parentElement).find('.usersNo').val();
            this.total = this.usersNo * this.price;
            console.log(this.price);
            console.log(this.usersNo);
            console.log(this.total);
        },
        changeUsers: function(type){
            if(type == "up"){
                if(this.usersNo < 10000){
                    this.usersNo = parseInt(this.usersNo) + 50
                }
            }else{
                if(this.usersNo > 50){
                    this.usersNo = parseInt(this.usersNo) - 50
                }
            }
        },
        newTask: function(){
            this.$parent.preloader('show')
            let vueThis = this
            let vueWin = window
            $.ajax({
                url: vueThis.$HOST_URL + 'api/new_task_type',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                    'platform':vueThis.platform,
                    'instruction':vueThis.editorData,
                    'type':vueThis.type,
                    'usersNo':vueThis.usersNo,
                    'socialLink':vueThis.socialLink,
                    'socialComment':vueThis.socialComment,
                    'countries':vueThis.countries,
                    'screenshot':vueThis.screenshot,
                },
                success: function(response){
                    console.log(response)
                    vueThis.$parent.noty(response.status,response.message);
                    vueThis.$parent.preloader('hide');
                    if (response.status == 'success') {
                        vueThis.$router.push({ path: '/advert/'+response.data['id'] })
                    }
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        changeDescription(){
            console.log(this.editorData,'>>>> ');
            if(this.editorData != ''){
                $(".viral-create-specific").show('slow')
            }else{
                $(".viral-create-specific").hide('slow')
            }
        },
        loadSummernote(){
            // setTimeout(function() {
            //     $('#instructions').summernote();
            // },5000);
            $(document).ready(function(){
                $("#instructions").summernote()
                console.log('>>> ready');
            });
        },
        sendLink(link){
            console.log(link);
            this.screenshot = link;
        },
        ContinueToAd(){
            this.infoSection = 'false';
        },
        ContinueToIn(){
            this.infoSection = 'true';
        },
    }
};
</script>
<style type="text/css">
    .new-advert-type .viral-create-specific {
        display: none;
    }
    .tabs {
        margin-bottom: 0;
    }
    .viral-advert-tab-host {
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
</style>
