<template>
    <div>
        <h4 class="text-bold">Choose Countries that can perform Ad</h4>
        <div>
            <template v-for="c in countries">
                <label>
                    <input type="checkbox" name="countries"  :value="c" v-on:click="updateCountry"> 
                     {{ c }} 
                </label>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        countries:['Nigeria','Ghana','Kenya'],
        country:[]
    }),
    mounted(){
    },
    methods: {
        getAdcountries () {
        },
        updateCountry(event){
            let sCountry = event.target['value']
            let country = this.country
            let found = 'false'
            let popIndex = 0
            for (var i = 0; i < this.country.length; i++) {
                let cCountry = this.country[i]
                if(cCountry == sCountry){
                    popIndex = i
                    found = 'true'
                }
                // console.log(cCountry)
            }
            if(found == 'false'){
                country.push(sCountry)
            }else{
                // console.log(popIndex,country,'POP')
                country.splice(popIndex,1)
            }
            // console.log(country,'Child')
            this.$parent.updateCountry(country)
            // let vueThis = this
            // setTimeout(function(){
            //     vueThis.$parent.updateCountry(country)
            // },1000)
        }
    }

};
</script>
<style type="text/css">
</style>
