<template>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">
        
            <div class="nano">
                <div class="nano-content">
                    <SideBar/>  
                </div>
        
            </div>
        
        </aside>
        <!-- end: sidebar -->

        <section role="main" class="content-body">
            <header class="page-header">
                <h2>Create Adverts</h2>
            
                <div class="right-wrapper pull-right">
                    <ol class="breadcrumbs">
                        <li>
                            <a href="create-advert">
                                <i class="fa fa-share-alt-square"></i> {{taskPrices.length}}
                            </a>
                        </li>
                    </ol>
                </div>
            </header>
            <div class="panel" style="margin-bottom:unset" v-if="taskPrices.length != []">
                <h2 class="create-advert-intro">Create Adverts</h2>
                <h4 class="text-bold">Choose an Advert</h4>
                <div class="col-sm-12 viral-col">
                    <div class="tabs viral-advert-tab-host">
                        <ul class="nav nav-tabs nav-justified  viral-adverts-select-tabs">
                            <li class="active text-center" data-toggle="tab" data-target="#facebook-advert-pane" v-on:click="changePlatform('facebook')" id="facebookTabButton">
                                <a data-toggle="tooltip" data-placement="top" title="Create Facebook Adverts" v-on:click="changeType('like',taskPrices['facebook']['like'])">
                                    <div class="text-center select-advert-box">
                                        <i class="fa fa-facebook fa-3x"></i>
                                    </div>
                                    Facebook Advert
                                </a>                    
                            </li>
                            <li class="" data-toggle="tab" data-target="#twitter-advert-pane" v-on:click="changePlatform('twitter')" id="twitterTabButton">
                                <a data-toggle="tooltip" data-placement="top" title="Create Twitter Adverts" v-on:click="changeType('follow',taskPrices['twitter']['follow'])">
                                    <div class="text-center select-advert-box">
                                        <i class="fa fa-twitter fa-3x"></i>
                                    </div>
                                    Twitter Advert
                                </a>
                            </li>
                            <li class="" data-toggle="tab" data-target="#instagram-advert-pane" v-on:click="changePlatform('instagram')" id="instagramTabButton">
                                <a data-toggle="tooltip" data-placement="top" title="Create Instagram Adverts" v-on:click="changeType('follow',taskPrices['instagram']['follow'])">
                                    <div class="text-center select-advert-box">
                                        <i class="fa fa-instagram fa-3x"></i>
                                    </div>
                                    Instagram Advert
                                </a>
                            </li>
                            <li  data-toggle="tab" data-target="#youtube-advert-pane" v-on:click="changePlatform('youtube')" id="youtubeTabButton">
                                <a data-toggle="tooltip" data-placement="top" title="Create Youtube Adverts" v-on:click="changeType('subscribe',taskPrices['youtube']['subscribe'])">
                                    <div class="text-center select-advert-box">
                                        <i class="fa fa-youtube fa-3x"></i>
                                    </div>
                                    Youtube Advert
                                </a>
                            </li>
                            <li  data-toggle="tab" data-target="#website-advert-pane" v-on:click="changePlatform('website')">
                                <a data-toggle="tooltip" data-placement="top" title="Create Website Adverts" v-on:click="changeType('subscribe',taskPrices['website']['vote'])">
                                    <div class="text-center select-advert-box">
                                        <i class="fa fa-desktop fa-3x"></i>
                                    </div>
                                    Website Advert
                                </a>
                            </li>
                            <li class="" data-toggle="tab" data-target="#tiktok-advert-pane" v-on:click="changePlatform('tiktok')" id="tiktokTabButton">
                                <a data-toggle="tooltip" data-placement="top" title="Create TikTok Adverts" v-on:click="changeType('like',taskPrices['tiktok']['like'])">
                                    <div class="text-center select-advert-box">
                                        <img src="/img/tiktok.svg" style="height: 40px" />
                                    </div>
                                    TikTok Advert
                                </a>                    
                            </li>
                            <li class="" data-toggle="tab" data-target="#threads-advert-pane" v-on:click="changePlatform('threads')" id="threadsTabButton">
                                <a data-toggle="tooltip" data-placement="top" title="Create Threads Adverts" v-on:click="changeType('like',taskPrices['threads']['like'])">
                                    <div class="text-center select-advert-box">
                                        <img src="/img/threads.png" style="height: 40px" />
                                    </div>
                                    Threads Advert
                                </a>                    
                            </li>
                        </ul>
                        
                        <div class="tab-content viral-adverts-select-tab-content">
                            <div id="facebook-advert-pane" class="tab-pane active">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h3>Choose a Facebook advert type</h3>
                                        <h4 class="text-bold">Facebook Adverts</h4>
                                        <p>Select any advert type from the provided facebook tasks you want users to partake in, and provide the required necessary details.</p>
                                    </div>
                                    <div class="col-sm-8 ">
                                        <div class="tabs viral-advert-tab-host" >
                                            <ul class="nav nav-tabs nav-justified  viral-adverts-tasks-tabs">
                                                <li class="" data-toggle="tab" data-target="#fb-create-like-pane">
                                                    <a v-on:click="changeType('like',taskPrices['facebook']['like'] )">
                                                        <input style="display:none" :value="taskPrices['facebook']['like']" id="fbLoad" type="text" class="form-control" />
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-thumbs-up fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Post/Image Likes</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['like'] }} / per like
                                                        </div>
                                                        
                                                    </a>                    
                                                </li>
                                                <li  data-toggle="tab" data-target="#fb-create-follow-pane" >
                                                    <a v-on:click="changeType('follow',taskPrices['facebook']['follow'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-group fa-3x "></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left;">FanPage Like/Followers</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['follow'] }} / per follower
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#fb-create-views-pane" >
                                                    <a v-on:click="changeType('view',taskPrices['facebook']['view'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Views</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['view'] }} / per view
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#fb-create-comment-pane" >
                                                    <a v-on:click="changeType('comment',taskPrices['facebook']['comment'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Comments</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['comment'] }} / per comment
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#fb-create-share-pane" >
                                                    <a v-on:click="changeType('share',taskPrices['facebook']['share'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Shares</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['share'] }} / per share
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content viral-create-specific row">
                                    <div id="fb-create-like-pane" class="tab-pane active">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Facebook Advert</h5>
                                                    <p>
                                                        Post/Image Likes &nbsp;&nbsp;
                                                        <span>{{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['like'] }} / per like</span>
                                                    </p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the post/page URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-url" class="form-control" placeholder="eg.: https://www.facebook.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Likes Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#facebook-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="fb-create-follow-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Facebook Advert</h5>
                                                    <p>FanPage Likes/Followers &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['follow'] }} / per page like</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Fanpage URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-url" class="form-control" placeholder="eg.: https://www.facebook.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Followers/Like Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#facebook-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="fb-create-views-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Facebook Advert</h5>
                                                    <p>Video views &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['view'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the video URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-url" class="form-control" placeholder="eg.: https://www.facebook.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Views Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#facebook-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="fb-create-comment-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Facebook Advert</h5>
                                                    <p>Comments &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['comment'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-url" class="form-control" placeholder="eg.: https://www.facebook.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Comments Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Comment Sample </label>
                                                        <span  style="color: red; font-size: 10px; font-weight:600;">Don't paste sample, if you want unique comments from users.</span>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-comment" class="form-control" placeholder="eg.: lovely post" required v-model="socialComment"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#facebook-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="fb-create-share-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Facebook Advert</h5>
                                                    <p>Shares &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['facebook']['share'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-url" class="form-control" placeholder="eg.: https://www.facebook.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Shares Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Share Sample </label>
                                                        <span  style="color: red; font-size: 10px; font-weight:600;">Don't paste sample, if you want unique share from users.</span>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-share" class="form-control" placeholder="eg.: lovely post" required v-model="socialComment"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#facebook-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>                                      
                            </div>
                            <div id="twitter-advert-pane" class="tab-pane">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h3>Choose a Twitter advert type</h3>
                                        <h4 class="text-bold">Twitter Adverts</h4>
                                        <p>Select any advert type from the provided twitter tasks you want users to partake in, and provide the required necessary details.</p>
                                    </div>
                                    <div class="col-sm-8 ">
                                        <div class="tabs viral-advert-tab-host" >
                                            <ul class="nav nav-tabs nav-justified  viral-adverts-tasks-tabs">
                                                <li class="active " data-toggle="tab" data-target="#tw-create-follow-pane" >
                                                    <a v-on:click="changeType('follow',taskPrices['twitter']['follow'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-group fa-3x "></i>
                                                            
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Followers</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['follow'] }} / per follower
                                                        </div>
                                                        
                                                    </a>                    
                                                </li>
                                                <li  data-toggle="tab" data-target="#tw-create-retweet-pane" >
                                                    <a v-on:click="changeType('retweet',taskPrices['twitter']['retweet'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-retweet fa-3x "></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left;">Retweets</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['retweet'] }} / per retweet
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tw-create-like-pane" >
                                                    <a v-on:click="changeType('like',taskPrices['twitter']['like'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-heart fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Likes</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['like'] }} / per like
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tw-create-comment-pane" >
                                                    <a v-on:click="changeType('comment',taskPrices['twitter']['comment'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-comment fa-3x "></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left;">Comments</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['comment'] }} / per retweet
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content viral-create-specific row">
                                    <div id="tw-create-follow-pane" class="tab-pane active">
                                        <form class="">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Twitter Advert</h5>
                                                    <p>Followers &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['follow'] }} / per follower</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the page/account URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="twitter-url" class="form-control" placeholder="eg.: https://twitter.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Followers Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#twitter-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tw-create-retweet-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Twitter Advert</h5>
                                                    <p>Retweets &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['retweet'] }} / per retweet</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="twitter-url" class="form-control" placeholder="eg.: https://twitter.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Retweets Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#twitter-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tw-create-like-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Twitter Advert</h5>
                                                    <p>Likes &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['like'] }} / per like</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the page/post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="twitter-url" class="form-control" placeholder="eg.: https://twitter.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Likes Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#twitter-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tw-create-comment-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Twitter Advert</h5>
                                                    <p>Comment &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['twitter']['comment'] }} / per comment</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="twitter-url" class="form-control" placeholder="eg.: https://twitter.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Comment Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#twitter-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                            <div id="instagram-advert-pane" class="tab-pane">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h3>Choose a Instagram advert type</h3>
                                        <h4 class="text-bold">Instagram Adverts</h4>
                                        <p>Select any advert type from the provided instagram tasks you want users to partake in, and provide the required necessary details.</p>
                                    </div>
                                    <div class="col-sm-8 ">
                                        <div class="tabs viral-advert-tab-host" >
                                            <ul class="nav nav-tabs nav-justified  viral-adverts-tasks-tabs">
                                                <li class="active " data-toggle="tab" data-target="#in-create-follow-pane" >
                                                    <a v-on:click="changeType('follow',taskPrices['instagram']['follow'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-group fa-3x "></i>
                                                            
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Followers</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['follow'] }} / per follower
                                                        </div>
                                                        
                                                    </a>                    
                                                </li>
                                                <li  data-toggle="tab" data-target="#in-create-view-pane" >
                                                    <a v-on:click="changeType('view',taskPrices['instagram']['view'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x "></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left;">Views</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['view'] }} / per view
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#in-create-like-pane" >
                                                    <a v-on:click="changeType('like',taskPrices['instagram']['like'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-heart fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Likes</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['like'] }} / per like
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#in-create-comment-pane" >
                                                    <a v-on:click="changeType('comment',taskPrices['instagram']['comment'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-heart fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Comments</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['comment'] }} / per comment
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content viral-create-specific row">
                                    <div id="in-create-follow-pane" class="tab-pane active">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Instagram Advert</h5>
                                                    <p>Followers &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['follow'] }} / per follower</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the page/account URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="instagram-url" class="form-control" placeholder="eg.: https://instagram.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Followers Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#instagram-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="in-create-view-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Instagram Advert</h5>
                                                    <p>Video Views &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['view'] }} / per video view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the post/video URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="instagram-url" class="form-control" placeholder="eg.: https://instagram.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Views Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#instagram-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="in-create-like-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Instagram Advert</h5>
                                                    <p>Likes &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['like'] }} / per like</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the page/post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="instagram-url" class="form-control" placeholder="eg.: https://instagram.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Likes Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#instagram-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="in-create-comment-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Instagram Advert</h5>
                                                    <p>Comments &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['instagram']['comment'] }} / per comment</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the page/post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="instagram-url" class="form-control" placeholder="eg.: https://instagram.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Comments Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Comment Sample</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="instagram-comment" class="form-control" placeholder="eg.: lovely post" required v-model="socialComment"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#instagram-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div id="youtube-advert-pane" class="tab-pane">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h3>Choose a Youtube advert type</h3>
                                        <h4 class="text-bold">Youtube Adverts</h4>
                                        <p>Select any advert type from the provided youtube tasks you want users to partake in, and provide the required necessary details.</p>
                                    </div>
                                    <div class="col-sm-8 ">
                                        <div class="tabs viral-advert-tab-host" >
                                            <ul class="nav nav-tabs nav-justified  viral-adverts-tasks-tabs">
                                                <li v-on:click="changeType('subscribe',taskPrices['youtube']['subscribe'] )" class="active " data-toggle="tab" data-target="#yb-create-subscribe-pane" >
                                                    <a >
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-group fa-3x "></i>
                                                            
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Subscribers</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['subscribe'] }} / per subscriber
                                                        </div>
                                                        
                                                    </a>                    
                                                </li>
                                                <li v-on:click="changeType('view',taskPrices['youtube']['view'] )" data-toggle="tab" data-target="#yb-create-view-pane" >
                                                    <a >
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x "></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left;">Views</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['view'] }} / per view
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-on:click="changeType('like',taskPrices['youtube']['like'] )" data-toggle="tab" data-target="#yb-create-like-pane" >
                                                    <a >
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-heart fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Likes</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['like'] }} / per like
                                                        </div>
                                                    </a>
                                                </li>
                                                <li v-on:click="changeType('comment',taskPrices['youtube']['comment'] )"  data-toggle="tab" data-target="#yb-create-comment-pane" >
                                                    <a v-on:click="changeType('comment',taskPrices['youtube']['comment'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-heart fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Comments</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['comment'] }} / per comment
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content viral-create-specific row">
                                    <div id="yb-create-subscribe-pane" class="tab-pane active">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Youtube Advert</h5>
                                                    <p>Subscribers &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['subscribe'] }} / per subscriber</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the channel URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="youtube-url" class="form-control" placeholder="eg.: https://youtube.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Subscribers Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#youtube-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                    <div id="yb-create-view-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Youtube Advert</h5>
                                                    <p>Video Views &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['view'] }} / per video view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the video URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="youtube-url" class="form-control" placeholder="eg.: https://youtube.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Views Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#youtube-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="yb-create-like-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Youtube Advert</h5>
                                                    <p>Likes &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['like'] }} / per like</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the video/page URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="youtube-url" class="form-control" placeholder="eg.: https://youtube.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Likes Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#youtube-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="yb-create-comment-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Youtube Advert</h5>
                                                    <p>Comments &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['comment'] }} / per comment</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the page/post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="youtube-url" class="form-control" placeholder="eg.: https://youtube.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Comments Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Comment Sample</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="youtube-comment" class="form-control" placeholder="eg.: lovely post" required v-model="socialComment"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#youtube-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                            <div id="website-advert-pane" class="tab-pane">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h3>Choose a Website advert type</h3>
                                        <h4 class="text-bold">Website Adverts</h4>
                                        <p>Select any advert type from the provided website tasks you want users to partake in, and provide the required necessary details.</p>
                                    </div>
                                    <div class="col-sm-8 ">
                                        <div class="tabs viral-advert-tab-host" >
                                            <ul class="nav nav-tabs nav-justified  viral-adverts-tasks-tabs">
                                                <li v-on:click="changeType('vote',taskPrices['website']['vote'] )" class="active " data-toggle="tab" data-target="#website-create-vote-pane" >
                                                    <a >
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-thumbs-up fa-3x "></i>
                                                            
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Votes</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['website']['vote'] }} / per vote
                                                        </div>
                                                        
                                                    </a>                    
                                                </li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content viral-create-specific row">
                                    <div id="website-create-vote-pane" class="tab-pane active">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Website Advert</h5>
                                                    <p>Votes &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['website']['vote'] }} / per vote</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the website URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="website-url" class="form-control" placeholder="eg.: https://website.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Voters Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Comment Sample</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-comment" class="form-control" placeholder="eg.: lovely post" required v-model="socialComment"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#website-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                    <div id="website-create-view-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Youtube Advert</h5>
                                                    <p>Video Views &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['view'] }} / per video view</span></p>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the video URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="youtube-url" class="form-control" placeholder="eg.: https://youtube.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Views Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#youtube-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="website-create-like-pane" class="tab-pane">
                                        <form class="" >
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Youtube Advert</h5>
                                                    <p>Likes &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['youtube']['like'] }} / per like</span></p>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the video/page URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="youtube-url" class="form-control" placeholder="eg.: https://youtube.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Likes Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#youtube-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div>
                            </div>
                            <div id="tiktok-advert-pane" class="tab-pane">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h3>Choose a TikTok advert type</h3>
                                        <h4 class="text-bold">TikTok Adverts</h4>
                                        <p>Select any advert type from the provided tiktok tasks you want users to partake in, and provide the required necessary details.</p>
                                    </div>
                                    <div class="col-sm-8 ">
                                        <div class="tabs viral-advert-tab-host" >
                                            <ul class="nav nav-tabs nav-justified  viral-adverts-tasks-tabs">
                                                <li class="active" data-toggle="tab" data-target="#tt-create-like-pane">
                                                    <a v-on:click="changeType('like',taskPrices['tiktok']['like'] )">
                                                        <input style="display:none" :value="taskPrices['tiktok']['like']" id="fbLoad" type="text" class="form-control" />
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-thumbs-up fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Post Likes</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['like'] }} / per like
                                                        </div>
                                                        
                                                    </a>                    
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-follow-pane" >
                                                    <a v-on:click="changeType('follow',taskPrices['tiktok']['follow'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-group fa-3x "></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left;">Followers</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['follow'] }} / per follower
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-views-pane" >
                                                    <a v-on:click="changeType('view',taskPrices['tiktok']['view'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Views</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['view'] }} / per view
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-comment-pane" >
                                                    <a v-on:click="changeType('comment',taskPrices['tiktok']['comment'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Comments</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['comment'] }} / per comment
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-save-pane" >
                                                    <a v-on:click="changeType('save',taskPrices['tiktok']['save'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Saves</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['save'] }} / per save
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content viral-create-specific row">
                                    <div id="tt-create-like-pane" class="tab-pane active">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">TikTok Advert</h5>
                                                    <p>
                                                        Post/Image Likes &nbsp;&nbsp;
                                                        <span>{{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['like'] }} / per like</span>
                                                    </p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="tiktok-url" class="form-control" placeholder="eg.: https://www.tiktok.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Likes Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#tiktok-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-follow-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">TikTok Advert</h5>
                                                    <p>FanPage Likes/Followers &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['follow'] }} / per page like</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Fanpage URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="tiktok-url" class="form-control" placeholder="eg.: https://www.tiktok.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Followers/Like Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#tiktok-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-views-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">TikTok Advert</h5>
                                                    <p>Video views &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['view'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the video URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="tiktok-url" class="form-control" placeholder="eg.: https://www.tiktok.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Views Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#tiktok-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-comment-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">TikTok Advert</h5>
                                                    <p>Comments &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['comment'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="tiktok-url" class="form-control" placeholder="eg.: https://www.tiktok.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Comments Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Comment Sample </label>
                                                        <span  style="color: red; font-size: 10px; font-weight:600;">Don't paste sample, if you want unique comments from users.</span>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-comment" class="form-control" placeholder="eg.: lovely post" required v-model="socialComment"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#tiktok-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-save-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">TikTok Advert</h5>
                                                    <p>Saves &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['tiktok']['save'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="tiktok-url" class="form-control" placeholder="eg.: https://www.tiktok.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Save Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#tiktok-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>                                      
                            </div>
                            <div id="threads-advert-pane" class="tab-pane">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <h3>Choose a Threads advert type</h3>
                                        <h4 class="text-bold">Threads Adverts</h4>
                                        <p>Select any advert type from the provided threads tasks you want users to partake in, and provide the required necessary details.</p>
                                    </div>
                                    <div class="col-sm-8 ">
                                        <div class="tabs viral-advert-tab-host" >
                                            <ul class="nav nav-tabs nav-justified  viral-adverts-tasks-tabs">
                                                <li class="active" data-toggle="tab" data-target="#tt-create-like-pane">
                                                    <a v-on:click="changeType('like',taskPrices['threads']['like'] )">
                                                        <input style="display:none" :value="taskPrices['threads']['like']" id="fbLoad" type="text" class="form-control" />
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-thumbs-up fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Post Likes</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['like'] }} / per like
                                                        </div>
                                                        
                                                    </a>                    
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-follow-pane" >
                                                    <a v-on:click="changeType('follow',taskPrices['threads']['follow'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-group fa-3x "></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left;">Followers</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['follow'] }} / per follower
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-views-pane" >
                                                    <a v-on:click="changeType('view',taskPrices['threads']['view'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Views</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['view'] }} / per view
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-comment-pane" >
                                                    <a v-on:click="changeType('comment',taskPrices['threads']['comment'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Comments</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['comment'] }} / per comment
                                                        </div>
                                                    </a>
                                                </li>
                                                <li  data-toggle="tab" data-target="#tt-create-save-pane" >
                                                    <a v-on:click="changeType('share',taskPrices['threads']['share'] )">
                                                        <div class="" style="padding:5px;">
                                                            <i class="fa fa-eye fa-3x"></i>
                                                        </div>
                                                        <span style="font-size:16px; text-bold; float-left">Shares</span>
                                                        <div class="create-advert-price">
                                                            {{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['share'] }} / per share
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content viral-create-specific row">
                                    <div id="tt-create-like-pane" class="tab-pane active">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Threads Advert</h5>
                                                    <p>
                                                        Post/Image Likes &nbsp;&nbsp;
                                                        <span>{{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['like'] }} / per like</span>
                                                    </p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="threads-url" class="form-control" placeholder="eg.: https://www.threads.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Likes Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#threads-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-follow-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Threads Advert</h5>
                                                    <p>FanPage Likes/Followers &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['follow'] }} / per page like</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Fanpage URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="threads-url" class="form-control" placeholder="eg.: https://www.threads.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">Followers/Like Target</label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#threads-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-views-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Threads Advert</h5>
                                                    <p>Video views &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['view'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the video URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="threads-url" class="form-control" placeholder="eg.: https://www.threads.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Views Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#threads-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-comment-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Threads Advert</h5>
                                                    <p>Comments &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['comment'] }} / per view</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="threads-url" class="form-control" placeholder="eg.: https://www.threads.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Comments Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Comment Sample </label>
                                                        <span  style="color: red; font-size: 10px; font-weight:600;">Don't paste sample, if you want unique comments from users.</span>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="facebook-comment" class="form-control" placeholder="eg.: lovely post" required v-model="socialComment"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#threads-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div id="tt-create-save-pane" class="tab-pane">
                                        <form class="" action="#">
                                            <div class="row viral-row">
                                                <div class="col-md-3 col-sm-12 viral-create-form-cont text-center" >
                                                    <h5 class="text-bold" style="color:#023562;">Threads Advert</h5>
                                                    <p>Shares &nbsp;&nbsp;<span>{{ localizationData['currencySymbol'] }} {{ taskPrices['threads']['share'] }} / per share</span></p>
                                                    <div>
                                                        <advertCountries/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <div class="form-group">
                                                        <label class="col-sm-12 control-label text-bold"  style="color:#023562;">Paste the Post URL/link</label>
                                                        <div class="col-sm-12">
                                                            <input type="url" name="threads-url" class="form-control" placeholder="eg.: https://www.threads.com/johndoe" required v-model="socialLink"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                    <label class="col-sm-12 control-label text-bold" style="color:#023562;">
                                                        Save Target
                                                    </label>
                                                    <div class="col-sm-12" data-plugin-spinner data-plugin-options='{ "value":100 , "step": 50, "min": 100, "max": 1000000 }'>
                                                        <div class="input-group" >
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('down')">
                                                                <button type="button" class="btn btn-default spinner-down">
                                                                    <i class="fa fa-minus"></i>
                                                                </button>
                                                            </div>
                                                            <input type="text" class="spinner-input form-control usersNo" maxlength="7" readonly required v-model="usersNo">
                                                            <div class="spinner-buttons input-group-btn" v-on:click="changeUsers('up')">
                                                                <button type="button" class="btn btn-default spinner-up">
                                                                    <i class="fa fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-sm-6 text-center viral-create-form-cont">
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-if="socialLink">
                                                    <a type="submit" class="btn btn-lg btn-primary viral-btn create-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#threads-task-modal" v-on:click="getTotal($event)">Create Advert</a>
                                                </div>
                                                <div class="col-md-3 col-sm-12 text-center viral-create-form-cont" v-else>
                                                    <button class="btn btn-default">Waiting for URL...</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>                                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <h3>
                    Loading...
                </h3>
            </div>

            <div class="">
                <!-- Modal Animation -->
                <div id="facebook-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Facebook Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-facebook-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a facebook advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;
                                        <span class="advert-task-value">
                                            {{ localizationData['currency'] }}
                                            <span id="advert-task-cost">{{ total }}</span>
                                    </span>
                                    </p>

                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p>
                                    <span><i class="fa fa-warning"></i> &nbsp;</span>
                                    You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.
                                </p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="twitter-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Twitter Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-twitter-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a twitter advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="instagram-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Instagram Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-instagram"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a instagram advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="youtube-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Youtube Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-youtube-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a youtube advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="website-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Website Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-youtube-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a youtube advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;<span class="advert-task-value">{{ localizationData['currency'] }}<span id="advert-task-cost">{{ total }}</span></span>
                                    </p>
                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p><span><i class="fa fa-warning"></i> &nbsp;</span>You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.</p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="tiktok-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">TikTok Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-tiktok-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a tiktok advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;
                                        <span class="advert-task-value">
                                            {{ localizationData['currency'] }}
                                            <span id="advert-task-cost">{{ total }}</span>
                                    </span>
                                    </p>

                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p>
                                    <span><i class="fa fa-warning"></i> &nbsp;</span>
                                    You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.
                                </p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
                <div id="threads-task-modal" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Threads Advert Task Creation</h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="modal-icon">
                                    <i class="fa fa-threads-square"></i>
                                </div>
                                <div class="modal-text ">
                                    <p class="advert-task-modal-text text-bold">Great, you are on the process of creating a threads advert task, proceed to payment to complete advert creation.</p>
                                    <p class="advert-task-modal-text">
                                        Advert Name :&nbsp;&nbsp;
                                        <span id="advert-task-name" class="advert-task-value">{{ type }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert URL :&nbsp;&nbsp;
                                        <span id="advert-task-url" class="">
                                        <a v-bind:href="type" target="_blank">{{ socialLink }}</a></span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Target :&nbsp;&nbsp;
                                        <span id="advert-task-target" class="advert-task-value">{{ usersNo }}</span>
                                    </p>
                                    <p class="advert-task-modal-text">
                                        Advert Cost :&nbsp;&nbsp;
                                        <span class="advert-task-value">
                                            {{ localizationData['currency'] }}
                                            <span id="advert-task-cost">{{ total }}</span>
                                    </span>
                                    </p>

                                </div>
                            </div>
                            <div class="text-center advert-task-modal-warning">
                                <p>
                                    <span><i class="fa fa-warning"></i> &nbsp;</span>
                                    You are required to pay the cost of your advert, kindly proceed to payment to complete advert creation.
                                </p>
                            </div>

                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                    <a class="btn btn-primary viral-btn" v-on:click="newTask">Proceed to payment</a>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>
            </div>
            <!-- end: page -->
        </section>
    </div>
</template>
<script>
import SideBar from "./../includes/SideBar";
import advertCountries from "./../includes/advertCountries";
export default {
    components: {
        SideBar,
        advertCountries,
    },
    data: () => ({
        baseUrl: "",
        taskPrices:[],
        localizationData:[],
        type:'',
        socialLink:'',
        usersNo:250,
        total:'',
        socialComment:'',
        accessToken:'',
        countries:[]
    }),
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.getAdRates()
        this.getLocalization()
    },
    methods: {
        init(){
            this.changePlatform("facebook")
            this.switchTab()
        },
        switchTab(){
            let q = window.location
            console.log(q,"//")
            let h = q.hash
            let vueThis = this
            if(h){
                $(document).ready(function(){
                    let fb = h.search('facebook')
                    let tw = h.search('twitter')
                    let ig = h.search('instagram')
                    let yt = h.search('youtube')
                    let tt = h.search('tiktok')
                    let ts = h.search('threads')
                    console.log(h,fb,tw,ig,yt)
                    $("#facebookTabButton").removeClass("active")
                    $("#twitterTabButton").removeClass("active")
                    $("#instagramTabButton").removeClass("active")
                    $("#youtubeTabButton").removeClass("active")
                    $("#tiktokTabButton").removeClass("active")
                    $("#threadsTabButton").removeClass("active")
                    if(fb > -1){
                        $("#facebookTabButton").addClass("active")
                        vueThis.changePlatform("facebook")
                    }
                    if(tw > -1){
                        $("#twitterTabButton").addClass("active")
                        vueThis.changePlatform("twitter")
                    }
                    if(ig > -1){
                        $("#instagramTabButton").addClass("active")
                        vueThis.changePlatform("instagram")
                    }
                    if(yt > -1){
                        $("#youtubeTabButton").addClass("active")
                        vueThis.changePlatform("youtube")
                    }
                    if(tt > -1){
                        $("#tiktokTabButton").addClass("active")
                        vueThis.changePlatform("tiktok")
                    }
                    if(ts > -1){
                        $("#threadsTabButton").addClass("active")
                        vueThis.changePlatform("threads")
                    }
                })
            }
        },
        updateCountry(country){
            console.log(country,">> PARENT")
            this.countries = country
        },
        getAdRates(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/ad_rates',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
                    console.log(response)
                    vueThis.taskPrices = response.data || []
                    vueThis.init()
                    if(vueThis.taskPrices.length != []){
                        vueThis.changeType(vueThis.platform,vueThis.taskPrices['facebook']['like'])
                    }
                    console.log(vueThis.taskPrices,vueThis.taskPrices.length)
                    if(response.status == 'success'){
                    }else{
                        vueThis.$parent.noty(response.status,response.message);
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        getLocalization(){
            this.$parent.preloader('show');
            this.processing = true
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/localization',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
                    console.log(response)
                    if(response.status == 'success'){
                        vueThis.localizationData = response.data
                    }else{
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        changePlatform: function(platform){
            this.usersNo = 250;
            this.platform = platform;
            if (platform == 'facebook') {
                this.type = 'like';
            }
            if (platform == 'twitter') {
                this.type = 'follow';
            }
            if (platform == 'instagram') {
                this.type = 'follow';
            }
            if (platform == 'youtube') {
                this.type = 'subscribe';
            }
            if (platform == 'website') {
                this.type = 'vote';
            }
            if (platform == 'tiktok') {
                this.type = 'like';
            }
            if (platform == 'threads') {
                this.type = 'like';
            }
            if (this.type == 'like') {
                this.usersNo = 250;
            }else{
                this.usersNo = 250;
            }
            console.log(this.platform,this.type,"platform and type")
        },
        changeType: function(type,price){
            console.log(type);
            console.log(price);
            if (this.type == 'like') {
                this.usersNo = 250;
            }else{
                this.usersNo = 250;
            }
            this.type = type;
            this.price = price;
        },
        changePrice: function(price){
            this.price = price;
            console.log(price);
        },
        priceDefault: function(){
            this.price = $("#fbLoad").val();
            console.log(this.price);
        },
        getTotal: function(event){
            var elem = event.target;
            this.usersNo = $(elem.parentElement.parentElement).find('.usersNo').val();
            this.total = this.usersNo * this.price;
            console.log(this.price);
            console.log(this.usersNo);
            console.log(this.total);
        },
        changeUsers: function(type){
            if(type == "up"){
                if(this.usersNo < 10000){
                    this.usersNo = parseInt(this.usersNo) + 50
                }
            }else{
                if(this.usersNo > 50){
                    this.usersNo = parseInt(this.usersNo) - 50
                }
            }
        },
        newTask: function(){
            this.$parent.preloader('show')
            $(".viral-btn").attr('disabled', true)
            let vueThis = this
            let vueWin = window
            $.ajax({
                url: vueThis.$HOST_URL + 'api/new_task',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                    'platform':vueThis.platform,
                    'type':vueThis.type,
                    'usersNo':vueThis.usersNo,
                    'socialLink':vueThis.socialLink,
                    'socialComment':vueThis.socialComment,
                    'countries':vueThis.countries,
                },
                success: function(response){
                    console.log(response)
                    vueThis.$parent.noty(response.status,response.message);
                    vueThis.$parent.preloader('hide');
                    if (response.status == 'success') {
                        vueThis.$router.push({ path: '/advert/'+response.data['id'] })
                    }
                    $(".viral-btn").attr('disabled', false)
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                    $(".viral-btn").attr('disabled', false)
                }
            })
        },
    }
};
</script>
<style type="text/css">
</style>
