<template>
  <div class="welcome">
    <slot />
  </div>
</template>
<script>
export default {};
</script>
<style type="text/css">
</style>
