require('babel-polyfill')

const auth = {
  namespaced: true,
  state: {
    accessToken: localStorage.getItem('access_token'),
    authenticated: false,
    currentUser: JSON.parse(localStorage.getItem('user'))
  },
  getters: {
    token: state => state.accessToken,
    isAuthenticated: state => state.authenticated,
    user: state => (state.currentUser === null) ? {} : state.currentUser,
    name: state => (state.currentUser === null) ? '' : state.currentUser.name
  },
  mutations: {
    storeToken (state, payload) {
      localStorage.setItem('access_token', payload.remember_token)
      localStorage.setItem('user', JSON.stringify(payload))
      state.authenticated = true
    },
    clearToken (state) {
      state.access_token = ''
      state.authenticated = false
      state.currentUser = ''
    }
  },
  actions: {
    async login ({ commit }, token) {
      commit('storeToken', token)
    },
    async logout ({ commit }) {
      localStorage.removeItem('user')
      commit('clearToken')
    }
  }
}

export default auth
