<template>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">
        
            <div class="nano">
                <div class="nano-content">
                  <SideBar/>  
                </div>
        
            </div>
        
        </aside>
        <!-- end: sidebar -->

        <section role="main" class="content-body">
            <header class="page-header">
                <h2>Profile</h2>
            
                <div class="right-wrapper pull-right">
                    <ol class="breadcrumbs">
                        <li>
                            <a href="profile.html">
                                <i class="fa fa-user"></i>
                            </a>
                        </li>
                    </ol>
                </div>
            </header>

            <div class="row">
                <div class="col-md-4 col-lg-3">
                    <section class="panel">
                        <div class="panel-body">
                            <header class="panel-heading bg-primary  mb-md">

                                <div class="widget-profile-info">
                                    <div class="profile-info">
                                        <h4 class="name text-semibold">{{ name }}</h4>
                                        <h5 class="role">Advertiser</h5>
                                        <div class="profile-footer" style="color:#dd8300;">
                                            <span id="user-state">{{ state }}</span>&nbsp;/&nbsp;<span id="user-country">{{ country }}</span>
                                        </div>
                                    </div>
                                </div>

                            </header>

                            <div class="widget-toggle-expand mb-md">
                                <div class="widget-header">
                                    <h6>Profile Completion</h6>
                                    <div class="widget-toggle">+</div>
                                </div>
                                <div class="widget-content-collapsed">
                                    <div class="progress progress-xs light">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" :style="'width: '+profilePercent+'%;'">
                                            {{ profilePercent }}%
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content-expanded">
                                    <ul class="simple-todo-list">
                                        <li v-if="profile == 'true'" class="completed">Profile Details</li>
                                        <li v-else>Profile Details</li>

                                        <li v-if="location == 'true'" class="completed">Location</li>
                                        <li v-else>Location</li>

                                        <li v-if="advert == 'true'" class="completed">Create an Advert</li>
                                        <li v-else>Create an Advert</li>

                                        <li v-if="social == 'true'" class="completed">Update Social Media Handles</li>
                                        <li v-else>Update Social Media Handles</li>
                                    </ul>
                                </div>
                            </div>
                            <hr class="dotted short">
                            <div class="social-icons-list">
                                <a rel="tooltip" data-placement="bottom" target="_blank" :href="facebook" data-original-title="Your Facebook Link">
                                    <i class="fa fa-facebook"></i><span>Facebook</span>
                                </a>
                                <a rel="tooltip" data-placement="bottom" :href="twitter" data-original-title="Your Twitter Link">
                                    <i class="fa fa-twitter"></i><span>Twitter</span>
                                </a>
                                <a rel="tooltip" data-placement="bottom" target="_blank" :href="instagram" data-original-title="Your instagram Link">
                                    <i class="fa fa-instagram"></i><span>Facebook</span>
                                </a>
                                <a rel="tooltip" data-placement="bottom" :href="youtube" data-original-title="Your Youtube Channel Link">
                                    <i class="fa fa-youtube"></i><span>Twitter</span>
                                </a>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="col-md-4 col-lg-5">
                    <div class="panel panel-primary">
                        <header class="panel-heading">
                            <h2 class="panel-title">Personal Information</h2>
                        </header>
                        <div class="panel-body">
                            <form  v-on:submit.prevent="updateSocial">
                                <div class="form-group ">
                                    <div class="col-sm-6 viral-col-6-1">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-user"></i>
                                            </span>
                                            <input type="text" v-model="name" name="firstname" class="form-control" value="John" readonly/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 viral-col-6-2">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-user"></i>
                                            </span>
                                            <input type="text" v-model="name" name="lastname" class="form-control" value="Doe" readonly/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-globe"></i>
                                            </span>
                                            <input type="text" v-model="country" name="user-state" value="Nigeria" class="form-control"  readonly/>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-flag"></i>
                                            </span>
                                            <input type="text" v-model="state" name="user-state" value="Rivers" class="form-control"  readonly/>

                                        </div>
                                    </div>
                                </div>      
                                <div class="form-group">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-facebook-square"></i>
                                            </span>
                                            <input type="url" v-model="facebook" name="fb-user-url" value="" class="form-control" placeholder="Paste your facebook Url" />
                                        </div>
                                    </div>
                                </div>  
                                <div class="form-group">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-twitter-square"></i>
                                            </span>
                                            <input type="url" v-model='twitter' name="tw-user-url" value="" class="form-control" placeholder="Paste your twitter Url" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-instagram"></i>
                                            </span>
                                            <input type="url" v-model="instagram" name="in-user-url" value="" class="form-control" placeholder="Paste your instagram Url" />
                                        </div>
                                    </div>
                                </div>  
                                <div class="form-group">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-youtube-square"></i>
                                            </span>
                                            <input type="url" v-model="youtube" name="yb-user-url" value="" class="form-control" placeholder="Paste your youtube Url" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row text-center ">
                                    <button type="submit" class="mb-xs mt-xs mr-xs btn btn-md btn-primary viral-btn text-center">
                                        Update Profile
                                    </button>
                                </div>
                                    
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div class="panel panel-primary">
                        <header class="panel-heading">
                            <h2 class="panel-title">Change Password</h2>
                        </header>
                        <div class="panel-body">
                            <form class="" v-on:submit.prevent="updatePassword">
                                <div class="form-group ">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-lock"></i>
                                            </span>
                                            <input type="password" name="user-current-password" class="form-control " placeholder="Current Password" minlength="6" required v-model='oldPassword'/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-shield"></i>
                                            </span>
                                            <input type="password" name="user-new-password" class="form-control" placeholder="New Password" minlength="6" required v-model="newPassword"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <div class="col-sm-12 viral-col">
                                        <div class="input-group">
                                            <span class="input-group-addon viral-input-addon">
                                                <i class="fa fa-shield"></i>
                                            </span>
                                            <input type="password" name="confirm-user-new-password" class="form-control " placeholder="Confirm New Password" minlength="6" required v-model="newPasswordAgain"/>
                                        </div>
                                    </div>
                                </div>                          
                                <div class="row text-center ">
                                    <button type="submit" class="mb-xs mt-xs mr-xs btn btn-md btn-primary viral-btn text-center">
                                        Create Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <!-- end: page -->
        </section>
    </div>
</template>
<script>
import SideBar from "./../includes/SideBar";
export default {
    components: {
        SideBar,
    },
    data: () => ({
        social:[],
        accessToken:'',
        baseUrl:'',
        state:'',
        country:'',
        name:'',
        email:'',
        facebook:'',
        twitter:'',
        instagram:'',
        youtube:'',
        oldPassword:'',
        newPassword:'',
        newPasswordAgain:'',
        profile:'',
        advert:'',
        location:'',
        social:'',
        profilePercent:0
    }),
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.init()
        this.getSocial()
    },
    methods: {
        init(){
            let user = localStorage.getItem('user')
            if(user){
                user = JSON.parse(user)
            }
            this.name = user['name']
            this.email = user['email']
            this.country = user['country']
            this.state = user['state']
            // console.log(this.name,this.email,this.country,this.state)
        },
        getSocial(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/social',
                method: 'POST',
                data: {'access_token':vueThis.accessToken},
                success: function(response){
                    console.log(response)
                    if(response.data != null){
                        vueThis.social = response.data
                        vueThis.facebook = vueThis.social['facebook']
                        vueThis.twitter = vueThis.social['twitter']
                        vueThis.instagram = vueThis.social['instagram']
                        vueThis.youtube = vueThis.social['youtube']
                        vueThis.profileCompletion()
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        updateSocial(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/update_social',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                    'facebook':vueThis.facebook,
                    'twitter':vueThis.twitter,
                    'instagram':vueThis.instagram,
                    'youtube':vueThis.youtube,
                },
                success: function(response){
                    console.log(response)
                    if(response.status == "success"){
                    }
                    vueThis.$parent.noty(response.status,response.message);
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        updatePassword(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/update_password',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                    'oldPassword':vueThis.oldPassword,
                    'newPassword':vueThis.newPassword,
                    'newPasswordAgain':vueThis.newPasswordAgain,
                },
                success: function(response){
                    console.log(response)
                    if(response.status == "success"){
                    }
                    vueThis.$parent.noty(response.status,response.message);
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        profileCompletion(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/profile_completion',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                },
                success: function(response){
                    console.log(response)
                    if(response.status == "success"){
                    }
                    // vueThis.$parent.noty(response.status,response.message);
                    vueThis.profile = response.profile
                    vueThis.advert = response.advert
                    vueThis.location = response.location
                    vueThis.social = response.social
                    vueThis.$parent.preloader('hide')
                    vueThis.calculateProfilePercent()
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        calculateProfilePercent(){
            if(this.advert == 'true'){
                this.profilePercent = this.profilePercent+25
            }
            if(this.location == 'true'){
                this.profilePercent = this.profilePercent+25
            }
            if(this.social == 'true'){
                this.profilePercent = this.profilePercent+25
            }
            if(this.profile == 'true'){
                this.profilePercent = this.profilePercent+25
            }
        }
    }
};
</script>
<style type="text/css">
</style>
