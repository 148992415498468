<template>
  <div>
    <site-header />
    <router-view />
    <site-footer />
  </div>
</template>
<script>
import SiteHeader from './Header.vue'
import SiteFooter from './Footer.vue'
export default {
  components: {
    SiteHeader,
    SiteFooter
  },
  methods: {
      preloader(type){
          if(type == 'show'){
              $("#preloader").show('slow');
              $("button").attr('disabled',true);
          }else{
              $("#preloader").hide('slow');
              $("button").attr('disabled',false);
          }
      },
      noty(status,message){
          console.log(status,message,">>> in parent")
          if (status == 'success') {
              toastr.success('Success',message);
          }
          if (status == 'warning') {
              toastr.warning('Warning',message);
          }
          if (status == 'failure') {
              toastr.error('Error',message);
          }
      },
      checkToken(data){
          let message = data['message'];
          console.log(message.toLowerCase().search("invalid token"),'>>>>>.......')
          if(message.toLowerCase().search("invalid token") > -1){
              this.noty("warning","session expired")
              localStorage.clear()
              this.$router.push({ path: '/login' })
          }
      }
  }
};
</script>
<style type="text/css">
	@import '/assets/css/login.css';
</style>
