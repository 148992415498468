<template>
  <section class="body-error error-outside">
    <div class="center-error">
      <div class="error-header">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <a href="/" class="logo">
                  <img src="images/logo.png" height="54" alt="ViralTrend" />
                </a>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="main-error mb-xlg">
            <h2 class="error-code text-center text-semibold m-none">500 <i class="fa fa-file"></i></h2>
            <p class="error-explanation text-center">We're sorry, but the page you were looking for doesn't exist.</p>
          </div>
        </div>
        <div class="col-md-4">
          <h4 class="text">Here are some useful links</h4>
          <ul class="nav nav-list primary">
            <li>
              <a :href="baseUrl+'login'"><i class="fa fa-caret-right text-dark"></i>Home Page</a>
            </li>
            <li>
              <a :href="baseUrl+'login'"><i class="fa fa-caret-right text-dark"></i>Login</a>
            </li>
            <li>
              <a :href="baseUrl+'register'"><i class="fa fa-caret-right text-dark"></i>Register</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  data: () => ({
    baseUrl: "",
  }),
  mounted () {
    this.baseUrl = this.$BASE_URL
  },
  methods: {
  }
};
</script>
<style type="text/css">
</style>
