<template>
    <!-- eslint-disable-next-line vue/require-component-is -->
    <component :is="layout">
        <transition name="slide" mode="out-in">
            <router-view/>
        </transition>
    </component>
</template>
<script>
export default {
    data: () => ({
        default: 'welcome'
    }),
    computed: {
        layout () {
            return (this.$route.meta.layout || this.default) + '-layout'
        }
    },
    mounted(){
        // load user data
        this.user = localStorage.getItem('user')
        console.log(this.user,'this user')
        if(this.user){
            this.user = JSON.parse(this.user)
            let routeName = this.$router.history.current['name'];
            console.log(routeName,'>>>>,<<<')
            if(routeName == 'dashboardL'){
                this.$router.push({ path: '/dashboard' })
            }
        }else{
            this.$router.push({ path: '/login' })
        }
    },
    methods: {
        preloader: function(type){
            if(type == 'show'){
                $("#preloader").show('slow');
                $("button").attr('disabled',true);
            }else{
                $("#preloader").hide('slow');
                $("button").attr('disabled',false);
            }
        },
        noty: function(status,message){
            if (status == 'success') {
                toastr.success('Success',message);
            }
            if (status == 'warning') {
                toastr.warning('Warning',message);
            }
            if (status == 'failure') {
                toastr.error('Error',message);
            }
        },
    }
};
</script>
<style type="text/css">
    @keyframes slide-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes slide-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 0.5s ease-in;
    }

    .fade-leave-active {
        transition: opacity 0.5s ease-out;
        opacity: 0;
    }
</style>
