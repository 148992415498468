<template>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">
        
            <div class="nano">
                <div class="nano-content">
                  <SideBar/>  
                </div>
        
            </div>
        
        </aside>
        <!-- end: sidebar -->

        <section role="main" class="content-body">
            <header class="page-header">
                <h2>Support</h2>
            
                <div class="right-wrapper pull-right">
                    <ol class="breadcrumbs">
                        <li>
                            <a href="support.html">
                                <i class="fa fa-support"></i>
                            </a>
                        </li>
                    </ol>
                </div>
            </header>

            <div class="panel">
                <div class="row">
                    <div class="col-xs-6">
                        <h2 class="m-none">Support Tickets</h2>
                    </div>
                    <div class="col-xs-6">
                        <a  class="mb-xs mt-xs mr-xs btn btn-md btn-primary viral-btn text-center pull-right" href="https://viraltrend.org" target="_blank">Frequently asked questions</a>
                    </div>
                </div>
                <div class="row">

                    <div class="panel-body" style="margin-top:30px;">
                        <div class="row text-center ">
                            <a class="mb-xs mt-xs mr-xs  btn btn-lg btn-primary viral-btn text-center modal-with-zoom-anim" data-toggle="modal" data-target="#create-support-ticket">Create Support Ticket</a>
                        </div>
                    </div>
                    <div class="panel  viral-support-panel" style="margin-top:30px;">
                        <header class="panel-heading" style=" background-color: #023562;">
                            <h2 class="panel-title" style="color:#ffffff;">Created Support Tickets</h2>
                        </header>
                        <div class="panel-body">
                            <p>
                                <button id="ticketRefresh" class="mb-xs mt-xs mr-xs btn btn-default" v-on:click="refreshTicket">
                                    <i class="fa fa-refresh"></i> Refresh
                                </button>
                            </p>
        
                            <div class="col-md-12"  id="pending-ticket">
                                <div class="row">
                                    <template v-for="thisTicket in tickets">
                                        <div class="col-md-3 col-sm-6 viral-col" v-if="thisTicket['status'] == 'pending'">
                                            <section class="panel support-tickets " id="">
                                                <header class="panel-heading portlet-handler support-pending-ticket">
                                                    <div class="panel-actions">
                                                        <a v-on:click="deleteTicket(ticket['id'])" class="fa fa-times" rel="tooltip" data-placement="top" data-original-title="Delete Ticket"></a>
                                                    </div>
                
                                                    <h2 class="panel-title">
                                                        {{ thisTicket['subject'] }} 
                                                        <small id="ticket-number" class="support-tcket-number">
                                                            {{ thisTicket['id'] }}
                                                        </small>
                                                    </h2>
                                                </header>
                                                <div class="row text-center ">
                                                    <a class="mb-sm mt-sm mr-xs btn btn-sm viral-btn support-details-btn text-center modal-with-move-anim" data-toggle="modal" data-target=".support-ticket-content" v-on:click="loadTicket(thisTicket)">
                                                        Details
                                                    </a>
                                                </div>
                                                
                                            </section>
                                        </div>
                                        <div class="col-md-3 col-sm-6 viral-col" v-else>
                                            <section class="panel support-tickets " id="">
                                                <header class="panel-heading portlet-handler support-closed-ticket">
                                                    <div class="panel-actions">
                                                        <a v-on:click="deleteTicket(ticket['id'])" class="fa fa-times" rel="tooltip" data-placement="top" data-original-title="Delete Ticket"></a>
                                                    </div>
                
                                                    <h2 class="panel-title">
                                                        {{ thisTicket['subject'] }} 
                                                        <small id="ticket-number" class="support-tcket-number">
                                                            {{ thisTicket['id'] }}
                                                        </small>
                                                    </h2>
                                                </header>
                                                <div class="row text-center ">
                                                    <a class="mb-sm mt-sm mr-xs btn btn-sm viral-btn support-details-btn text-center modal-with-move-anim" data-toggle="modal" data-target=".support-ticket-content" v-on:click="loadTicket(thisTicket)">
                                                        Details
                                                    </a>
                                                </div>
                                                
                                            </section>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="create-support-ticket" class="modal fade">
                    <section class="panel modal-dialog" role="document">
                        <header class="panel-heading">
                            <h2 class="panel-title">Support Ticket Creation</h2>
                        </header>
                        <div class="panel-body ticket-creation-panel-body">
                            <div class="modal-wrapper ticket-creation-wrapper">
                                <form class="" v-on:submit.prevent="createTicket">
                                    <div class="form-group">
                                        <div class="col-sm-12 viral-col">
                                            <div class="input-group">
                                                <span class="input-group-addon viral-input-addon">
                                                    <i class="fa fa-ticket"></i>
                                                </span>
                                                <select data-plugin-selectTwo class="form-control populate  viral-select" name="ticket-topics" required v-model="subject">
                                                    <option value="" selected="selected">- Select a Ticket Topic -</option>
                                                    <option value="Account">Account</option>
                                                    <option value="Advert">Advert</option>
                                                    <option value="Billing">Billing</option>
                                                    <option value="Suggest">I have a suggestion</option>
                                                </select>
        
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="form-group ">
                                        <div class="col-sm-12 viral-col">
                                            <div class="input-group">
                                                <span class="input-group-addon viral-input-addon">
                                                    <i class="fa fa-question-circle"></i>
                                                </span>
                                                <textarea v-model="message" class="form-control" rows="3" placeholder="Write your issue here." id="textareaAutosize" data-plugin-textarea-autosize="" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 60px;" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group ">
                                        <div class="col-sm-12 viral-col">
                                            <div class="input-group">
                                                <span class="input-group-addon viral-input-addon">
                                                    <i class="fa fa-external-link-square"></i>
                                                </span>
                                                <input type="text" id="fileLink" name="user-issue-extra-info" class="form-control " placeholder="Extra Info, eg: Url/Link" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group hidden">
                                        <div class="col-sm-12 viral-col">
                                            <div class="input-group">
                                                <span class="input-group-addon viral-input-addon">
                                                    <i class="fa fa-upload"></i>
                                                </span>
                                                <div class="fileupload fileupload-new" data-provides="fileupload">
                                                    <DykeUploads
                                                    class="file"
                                                    :max-size="50*1024*1024"
                                                    upload-url="api/file-upload">
                                                    </DykeUploads>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row text-right panel-footer">
                                        <button class="btn btn-default modal-dismiss" data-dismiss="modal">Cancel</button>
                                        <button class="btn btn-primary viral-btn" type="submit">Submit Ticket</button>
                                    </div>
                                                            
                                </form>
                            </div>
                            
                        </div>
                    </section>
                </div>

                <div id="support-ticket-content" class="modal fade support-ticket-content">
                    <section class="panel panel-primary modal-dialog" role="document" v-if="ticket">
                        <header class="panel-heading">
                            <h2 class="panel-title">
                                Support Ticket 
                                <span id="ticket-number" class="support-tcket-number">
                                    {{ ticket['id'] }}
                                </span>
                            </h2>
                        </header>
                        <div class="panel-body">
                            <div class="modal-wrapper">
                                <div class="col-sm-12 text-right">
                                    <span class="label label-success" v-if="ticket['status'] == 'active'">
                                        In Progress
                                    </span>
                                    <span class="label label-default" v-else-if="ticket['status'] == 'complete'">
                                        Closed
                                    </span>
                                    <span class="label label-danger" v-else>
                                        Pending
                                    </span>
                                </div>
                                <form v-on:submit.prevent="">
                                    
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="control-label" style="margin-top:10px;">Ticket Topic</label>
                                                <input type="text" name="user-content-ticket-topic" value="Account" class="form-control" readonly :value="ticket['subject']">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="control-label" style="margin-top:10px;">Ticket Url</label>
                                                <input type="text" name="user-content-ticket-extra-url" value="Account" class="form-control" readonly :value="ticket['url']">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label class="control-label" style="margin-top:10px;">Ticket Issue Description</label>
                                                <textarea class="form-control" rows="5" data-plugin-textarea-autosize="" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 60px;" readonly>{{ ticket['message'] }}</textarea>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 text-left" style="margin-top:20px;">
                                        <span class="label label-info">Response</span>
                                    </div>
                                    
                                    <div class="col-sm-12 ticket-response-content">
                                        <p class="" v-if="ticket.reply">{{ ticket['reply'] }}</p>
                                        <p class="" v-else>No Reply yet</p>
                                    </div>
                                    
                                </form>

                            </div>
                            
                        </div>
                        <footer class="panel-footer">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <button class="btn btn-dark modal-dismiss" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </footer>
                    </section>
                </div>

                
            </div>
            <!-- end: page -->
        </section>
    </div>
</template>
<script>
import SideBar from "./../includes/SideBar";
import DykeUploads from "./../includes/DykeUploads";
export default {
    components: {
        SideBar,
        DykeUploads,
    },
    data: () => ({
        subject:'',
        message:'',
        tickets:[],
        ticket:[],
    }),
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.getTickets()
    },
    methods: {
        getTickets(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/tickets',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                },
                success: function(response){
                    console.log(response)
                    vueThis.tickets = response.data
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        createTicket(){
            this.$parent.preloader('show');
            let url = $("#fileLink").val();
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/new_ticket',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                    'subject':vueThis.subject,
                    'message':vueThis.message,
                    'url':url,
                },
                success: function(response){
                    console.log(response)
                    if(response.status == "success"){
                        vueThis.tickets = response.data
                        vueThis.subject = ""
                        vueThis.message = ""
                        vueThis.url = ""
                    }
                    vueThis.$parent.noty(response.status,response.message)
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        loadTicket(t){
            console.log(t,"....")
            this.ticket = t
        },
        refreshTicket(){
            this.$parent.noty("success","Refreshing ticket list")
            this.getTickets()
        },
        deleteTicket(id){
            this.$parent.preloader('show');
            let url = $("#fileLink").val();
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/delete_ticket',
                method: 'POST',
                data: {
                    'access_token':vueThis.accessToken,
                    'key':id,
                },
                success: function(response){
                    console.log(response)
                    if(response.status == "success"){
                        vueThis.tickets = response.data
                    }
                    vueThis.$parent.noty(response.status,response.message)
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        }
    }
};
</script>
<style type="text/css">
</style>
