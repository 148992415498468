<template>
<div class="inner-wrapper">
    <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">

        <div class="nano">
            <div class="nano-content">
                <SideBar />
            </div>

        </div>

    </aside>

    <section role="main" class="content-body">
        <div class="row">
            <div class="col-sm-4">

                <section class="panel">
                    <div class="panel-body">
                        <header class="panel-heading bg-primary  mb-md">

                            <div class="widget-profile-info">
                                <div class="profile-info">
                                    <h4 class="name text-semibold">{{ user['name'] }}</h4>
                                    <h5 class="role">{{ user['email'] }}</h5>
                                    <div class="profile-footer" style="color:#dd8300;">
                                        <span id="user-state">{{ user['country'] }}</span>&nbsp;-&nbsp;
                                        <span id="user-country">{{ user['state'] }}</span>
                                    </div>
                                </div>
                            </div>

                        </header>

                        <div class="widget-toggle-expand mb-md">
                            <div class="widget-header">
                                <h6>Wallet details</h6>
                            </div>
                            <div class="widget-content-collapsed">
                            </div>
                            <div class="widget-content-expanded">
                                <ul class="simple-todo-list" v-if="wallet">
                                    <li>Amount: {{ currencyCode }} {{ parseFloat(wallet['amount']).toFixed(2) }}</li>
                                </ul>
                            </div>
                        </div>
                        <hr class="dotted short">
                        <div class="social-icons-list">
                            <span class="label label-warning">{{ wallet['status'] }}</span>
                        </div>
                    </div>
                </section>
            </div>
            <div class="col-sm-8">
                <section class="panel" v-if="fundAmount">
                    <div class="panel-body">
                        <header class="panel-heading bg-primary  mb-md">

                            <div class="widget-profile-info">
                                <div class="profile-info">
                                    <h4 class="name text-semibold">Choose Payment Method</h4>
                                </div>
                            </div>

                        </header>

                        <div class="widget-toggle-expand mb-md">
                            <div class="widget-header">
                            </div>
                            <div class="widget-content-collapsed">
                            </div>
                            <div class="widget-content-expanded">
                                <div class="row text-center">
                                    <div class="col-sm-12">
                                        <div v-if="loadRave == 'true'">
                                            <Rave style-class="btn btn-success" is-production="false" :email="email" :amount="fundAmount" :reference="reference" :rave-key="raveKey" :callback="callback" :close="close" :metadata="meta" :customerFirstname="fname" paymentOptions="card,barter,account,ussd" :currency="currencyCode" :country="countryCode"><i>Pay with Rave</i></Rave>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="dotted short">
                        <div class="social-icons-list">
                            <span class="label label-warning">
                                Note: Account will only be funded once payment is made
                            </span>
                        </div>
                    </div>
                </section>
                <section class="panel" v-else>
                    <div class="panel-body">
                        <header class="panel-heading bg-primary  mb-md">

                            <div class="widget-profile-info">
                                <div class="profile-info">
                                    <h4 class="name text-semibold">Fund your wallet</h4>
                                </div>
                            </div>

                        </header>

                        <div class="widget-toggle-expand mb-md">
                            <div class="widget-header">
                            </div>
                            <div class="widget-content-collapsed">
                            </div>
                            <div class="widget-content-expanded row">
                                <div class="col-sm-6">
                                    <input type="number" name="" v-model="amount" class="form-control" />
                                </div>
                                <div class="col-sm-4">
                                    <button class="btn btn-primary" v-on:click="proceedWithFunding">Proceed</button>
                                </div>
                            </div>
                        </div>
                        <hr class="dotted short">
                        <div class="social-icons-list">
                            <span class="label label-warning">
                                Note: Account will only be funded once payment is made
                            </span>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>

</div>
</template>

<script>
import Rave from 'vue-ravepayment';
import SideBar from "./../includes/SideBar";
export default {
    components: {
        SideBar,
        Rave,
    },
    data: () => ({
        baseUrl: "",
        fundAmount: 0,
        amount: 0,
        minAmount: 100,
        wallet: [],
        user: [],
        raveProdEnv: "false",
        loadRave: "",
        raveKey: "",
        currencyCode: "",
        countryCode: "",
        email: "",
        fname: "",
        lname: "",
        redirect: "",
        meta: [{
            metaname: 'Viral Trend',
            metavalue: 'Advert Payment'
        }],
        sub: [{
                id: "1324"
            },
            {
                id: "1221"
            }
        ],
        raveKey: '',
        currencyCode: '',
        countryCode: '',
        raveProdEnv: 'false',
    }),
    computed: {
        reference() {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 10; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }
    },
    mounted() {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.init()
        this.getWallet()
    },
    methods: {
        init() {
            // load user data
            this.user = localStorage.getItem('user')
            if (this.user) {
                this.user = JSON.parse(this.user)
            }
            this.fname = this.user['name']
            this.email = this.user['email']
            console.log(this.email, this.fname)
        },
        getWallet() {
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/wallet',
                method: 'POST',
                data: {
                    'access_token': vueThis.accessToken
                },
                success: function (response) {
                    console.log(response)
                    // vueThis.wallet = response.data
                    vueThis.wallet = response.data
                    vueThis.raveKey = response.rave['publicKey']
                    vueThis.currencyCode = response.rave['currencyCode']
                    vueThis.countryCode = response.rave['countryCode']
                    if (vueThis.raveKey) {

                    } else {
                        this.$toastr.w('Warning', 'Kindly reload this page')
                    }
                    if (response.rave['account'] == 'live') {
                        console.log("Rave live")
                        vueThis.raveProdEnv = "true"
                    } else {
                        vueThis.raveProdEnv = "false"
                        console.log("Rave test")
                    }
                    vueThis.$parent.preloader('hide')
                },
                error: function (response) {
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        proceedWithFunding() {
            if (this.amount > this.minAmount) {
                this.fundAmount = parseInt(this.amount)
                this.loadRave = "true"
                console.log(this.fundAmount, "F MA")
            } else {
                this.$toastr.w('Warning', 'Can not fund below ' + this.minAmount)
            }
        },
        callback: function (response) {
            console.log(response, "final response")
            let txRef = response['tx']['txRef'];
            if (txRef) {
                this.$parent.preloader('show');
                let vueThis = this;
                let vueWin = window;
                $.ajax({
                    url: vueThis.$HOST_URL + 'api/fund_wallet',
                    method: 'POST',
                    data: {
                        'access_token': vueThis.accessToken,
                        'transaction_id': txRef
                    },
                    success: function (response) {
                        console.log(response, "fund response")
                        vueThis.$parent.noty(response.status, response.message)
                        if (response.status == "success") {
                            vueWin.location.href = vueWin.location.href
                        }
                        vueThis.$parent.preloader('hide')
                    },
                    error: function (response) {
                        console.log(response)
                        vueThis.$parent.preloader('hide')
                    }
                })
            } else {
                this.$toastr.w('Warning', 'Transaction reference could not be found')
            }
        },
        close: function () {
            console.log("Payment closed")
        }
    }
};
</script>

<style type="text/css">
</style>
