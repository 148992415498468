<template>
    <div class="inner-wrapper">
        <!-- start: sidebar -->
        <aside id="sidebar-left" class="sidebar-left viral-sidebar-left">
        
            <div class="nano">
                <div class="nano-content">
                  <SideBar/>  
                </div>
        
            </div>
        
        </aside>
        <!-- end: sidebar -->

        <section role="main" class="content-body">
            <header class="page-header">
                <h2>History</h2>
            
                <div class="right-wrapper pull-right">
                    <ol class="breadcrumbs">
                        <li>
                            <a href="">
                                <i class="fa fa-database"></i>
                            </a>
                        </li>
                    </ol>
                </div>
            </header>

            <div class="panel viral-ipad-panel">
                <h2 class="create-advert-intro">Created Adverts history</h2>
                <h4 class="text-bold">View your completed and pending adverts</h4>

                <div class="tabs ">
                    <ul class="nav nav-tabs nav-justified adverts-history-tab">
                        <li class="active">
                            <a href="#pending-advert-history" data-toggle="tab">Pending Adverts</a>
                        </li>
                        <li class="">
                            <a href="#completed-advert-history" data-toggle="tab">Completed Adverts</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="pending-advert-history" class="tab-pane active">
                            <table class="table table-striped mb-none" id="pending-advert-history-table-details">
                                <thead>
                                    <tr>
                                        <th>Advert Name &nbsp;(<span>type</span>)</th>
                                        <th>ID</th>
                                        <th>URL/Link</th>
                                        <th>Target</th>
                                        <th>Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="" v-for="advert in pendingAdverts">
                                        <td class="adverts-history-td">
                                            <router-link :to="'/advert/'+advert['id']">
                                                {{ advert['platform'] }} &nbsp;(<span>{{ advert['type'] }}</span>)
                                            </router-link>
                                        </td>
                                        <td class="adverts-history-td">
                                            <a :href="'/advert/'+advert['id']" target="_blank">
                                                {{ advert['id'] }}
                                            </a>
                                        </td>
                                        <td class="adverts-history-td">
                                            <a :href="advert['url']" target="_blank">
                                                {{ advert['url'] }}
                                            </a>
                                        </td>
                                        <td class="adverts-history-td">{{ advert['users_needed'] }}</td>
                                        <td class="adverts-history-td">{{ advert['created_at'] }}</td>
                                        <td class="adverts-history-td">
                                            <span class="label label-success" v-if="advert['status'] == 'active'">
                                                In Progress
                                            </span>
                                            <span class="label label-warning" v-else>
                                                {{ advert['status'] }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div id="completed-advert-history" class="tab-pane">
                            <table class="table table-striped mb-none" id="completed-advert-history-table-details">
                                <thead>
                                    <tr>
                                        <th>Advert Name &nbsp;(<span>type</span>)</th>
                                        <th>ID</th>
                                        <th>URL/Link</th>
                                        <th>Target</th>
                                        <th>Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="" v-for="advert in completeAdverts" v-bind:key="advert['id']">
                                        <td class="adverts-history-td">
                                            <router-link :to="'/advert/'+advert['id']">
                                                {{ advert['platform'] }} &nbsp;(<span>{{ advert['type'] }}</span>)
                                            </router-link>
                                        </td>
                                        <td class="adverts-history-td">
                                            <a :href="'/advert/'+advert['id']" target="_blank">
                                                {{ advert['id'] }}
                                            </a>
                                        </td>
                                        <td class="adverts-history-td">
                                            <a :href="advert['url']" target="_blank">
                                                {{ advert['url'] }}
                                            </a>
                                        </td>
                                        <td class="adverts-history-td">{{ advert['users_needed'] }}</td>
                                        <td class="adverts-history-td">{{ advert['created_at'] }}</td>
                                        <td class="adverts-history-td"><span class="label label-primary">Completed</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>

            <!-- end: page -->
        </section>
    </div>
</template>
<script>
import SideBar from "./../includes/SideBar";
export default {
    components: {
        SideBar,
    },
    data: () => ({
        baseUrl:"",
        accessToken:"",
        pendingAdverts:[],
        completeAdverts:[],
    }),
    mounted () {
        this.accessToken = localStorage.getItem('access_token')
        this.baseUrl = this.$BASE_URL
        this.getPendingAdverts()
        this.getCompleteAdverts()
    },
    methods: {
        getPendingAdverts(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/tasks',
                method: 'POST',
                data: {'access_token':vueThis.accessToken,'type':'pending'},
                success: function(response){
                    console.log(response)
                    vueThis.pendingAdverts = response.data
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
        getCompleteAdverts(){
            this.$parent.preloader('show');
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/tasks',
                method: 'POST',
                data: {'access_token':vueThis.accessToken,'type':'complete'},
                success: function(response){
                    console.log(response)
                    vueThis.completeAdverts = response.data
                    vueThis.$parent.preloader('hide')
                },
                error: function(response){
                    console.log(response)
                    vueThis.$parent.preloader('hide')
                }
            })
        },
    }
};
</script>
<style type="text/css">
</style>
