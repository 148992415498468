import VueRouter from 'vue-router'
import VueScrollTo from 'vue-scrollto'

import Home from './components/layouts/Home.vue'
import Error from './components/pages/404.vue'
import Login from './components/pages/Login.vue'
import AutoLogin from './components/pages/AutoLogin.vue'
import Wallet from './components/pages/Wallet.vue'
import NewAdvert from './components/pages/NewAdvert.vue'
import Advert from './components/pages/Advert.vue'
import Dashboard from './components/pages/Dashboard.vue'
import History from './components/pages/History.vue'
import Profile from './components/pages/Profile.vue'
import Support from './components/pages/Support.vue'
import NewAdvertType from './components/pages/NewAdvertType.vue'
import HowTo from './components/pages/HowTo.vue'

let router = new VueRouter({
    mode: 'history',
    routes: [{
            path: '/login',
            name: 'login',
            meta: {
                layout: 'welcome',
                requiresAuth: false
            },
            component: Login
        },
        {
            path: '/how-to',
            name: 'how-to',
            meta: {
                layout: 'welcome',
                requiresAuth: false
            },
            component: HowTo
        },
        {
            path: '/auto/:key',
            name: 'auto',
            meta: {
                layout: 'welcome',
                requiresAuth: false
            },
            component: AutoLogin
        },
        {
            path: '/auto_login/:key',
            name: 'auto_login',
            meta: {
                layout: 'welcome',
                requiresAuth: false
            },
            component: AutoLogin
        },
        {
            path: '/',
            name: 'dashboardL',
            meta: {
                layout: 'default'
            },
            component: Home,
            children: [{
                path: 'dashboard',
                name: 'dashboard',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Dashboard
            }, {
                path: 'wallet',
                name: 'wallet',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Wallet
            }, {
                path: 'new-advert',
                name: 'new-advert',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: NewAdvert
            }, {
                path: 'new-advert-type',
                name: 'new-advert-type',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: NewAdvertType
            }, {
                path: 'advert/:key',
                name: 'advert',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Advert
            }, {
                path: 'history',
                name: 'history',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: History
            }, {
                path: 'profile',
                name: 'profile',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Profile,
                props: true
            }, {
                path: 'support',
                name: 'support',
                meta: {
                    layout: 'default',
                    requiresAuth: true
                },
                component: Support
            }]
        },
        {
            path: '*',
            name: '404',
            meta: {
                layout: 'welcome',
                requiresAuth: false
            },
            component: Error
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            VueScrollTo.scrollTo(to.hash, 700)
            return {
                selector: to.hash
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})
router.beforeEach((to, from, next) => {
    let url = location.href 
    if(url.search('viraltrend.org') > -1){
        location.href = 'https://advertiser.vitisocial.com'
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        let token = localStorage.getItem('access_token')
        if (token == null) {
            next({
                path: '/login',
                params: {
                    nextUrl: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})
export default router