<template>
    <section class="body-sign">
        <div class="center-sign">
            <a href="/" class="logo pull-left">
                <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
            </a>

            <div class="panel panel-sign">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> How to</h2>
                </div>
            </div>

            <p class="text-center text-muted mt-md mb-md" >
                &copy; Copyright 2020, 
                <span>
                    <a href="https://viraltrend.org">ViralTrend</a>
                </span>
            </p>
        </div>
    </section>
</template>
<script>
export default {
    data: () => ({
        baseUrl: "",
        key: "",
    }),
    mounted () {
        this.baseUrl = this.$BASE_URL
    },
    methods: {
    }
};
</script>
<style type="text/css">
    #noEmail, #noVerification {
        display: none;
    }
</style>
