<template>
    <section class="body-sign">
        <div class="center-sign">
            <a href="/" class="logo pull-left">
                <img src="/images/logo.png" height="54" alt="ViralTrend &dash; Go Viral" />
            </a>

            <div class="panel panel-sign">
                <div class="panel-title-sign mt-xl text-right">
                    <h2 class="title text-uppercase text-bold m-none"><i class="fa fa-user mr-xs"></i> Log In</h2>
                </div>
                <div class="panel-body">
                    <form id="" action="forms-validation.html" v-on:submit.prevent="loginAPI">
                        <div class="form-group mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-envelope"></i>
                                    </span>
                                    <input type="email" name="email" v-model="email" class="form-control input-lg" placeholder="E-mail Address" required/>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-lg">
                            <div class="col-sm-12 viral-col">
                                <div class="input-group">
                                    <span class="input-group-addon viral-input-addon">
                                        <i class="fa fa-key"></i>
                                    </span>
                                    <input type="password" v-model="password" name="password" class="form-control input-lg" placeholder="Password" required/>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="checkbox-custom checkbox-default">
                                    <input id="RememberMe" name="rememberme" type="checkbox"/>
                                    <label for="RememberMe">Keep me logged in</label>
                                </div>
                            </div>
                            <div class="col-sm-4 text-right">
                                <a :href="baseUrl+'recover-password'">Forgot password?</a>
                            </div>
                        </div>
                        <div class="alert alert-warning viral-form-alert" id="noEmail">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <strong> 
                                Oops! Email does not exist. 
                                <span> <a :href="baseUrl+'register.html'">Want to Register?</a> </span>
                            </strong> 
                        </div>
                        <div class="alert alert-warning viral-form-alert" id="noVerification">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                            <strong> 
                                Oops! Email not verified. 
                                <span> <a :href="baseUrl+'email-verify'">Verify your Email?</a> </span>
                            </strong> 
                        </div>
                        <div class="row text-center viral-reg-btn-div">
                            <button type="submit" class="mb-xs mt-xs mr-xs btn btn-lg btn-primary viral-btn text-center">
                                Continue
                            </button>
                        </div>

                        <p class="text-center" >
                            Don't have an account? <a :href="baseUrl+'register'">Register!</a>
                        </p>
                                
                    </form>
                </div>


            </div>

            <p class="text-center text-muted mt-md mb-md" >
                &copy; Copyright 2020, 
                <span>
                    <a href="https://viraltrend.org">ViralTrend</a>
                </span>
            </p>
        </div>
    </section>
</template>
<script>
export default {
    data: () => ({
        baseUrl: "",
        email: "",
        password: "",
    }),
    mounted () {
        this.baseUrl = this.$BASE_URL
        console.log("this.baseUrl",this.baseUrl)
        console.log("this.AdvertiserHost",this.$AdvertiserHost)
    },
    methods: {
        loginAPI(){
            this.processing = true

            if (this.email === '' || this.password === '') {
                this.processing = false
                this.$toastr.w('Warning', 'Please fill in complete login details')
                return false
            }
            let user = {
                email: this.email,
                password: this.password
            }
            let vueThis = this;
            let vueWin = window;
            $.ajax({
                url: vueThis.$HOST_URL + 'api/login',
                method: 'POST',
                data: user,
                success: function(response){
                    console.log(response)
                    if(response.status == 'success'){
                        vueThis.$toastr.s('Success', 'Login successful, loading dashboard')
                        vueThis.email = ''
                        vueThis.password = ''
                        
                        // if(response.user['type'] == 'advertiser'){
                            vueThis.$store.dispatch('auth/login', response.user).then(() => {
                                // if(response.user['type'] == 'advertiser'){
                                    window.location.href = '/dashboard'
                                    // vueThis.$router.push({ path: '/dashboard' })
                                // }else if(response.user['type'] == 'promoter'){
                                //     // window.location.href = vueThis.$PromoterHost+'dashboard'
                                //     // vueThis.$router.push({ path: '/dashboard' })
                                // }else{
                                //     vueThis.$toastr.e('Error', 'User type error')
                                // }
                                // window.location.href = "/#/dashboard"
                            })
                        // }else{
                        //     vueThis.$toastr.e('Error', 'This section is for advertisers only')
                        // }
                    }else{
                        vueThis.$toastr.e('Error', response.message)
                    }
                },
                error: function(response){
                    console.log(response)
                }
            })
        }
    }
};
</script>
<style type="text/css">
    #noEmail, #noVerification {
        display: none;
    }
</style>
